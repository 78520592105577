/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import * as ncbHttpServices from '../../services/ncb_services';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SideBar from '../dashboard'

import { Table, TableCell, TableBody, TableRow, TableContainer, TableHead, TableFooter } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from '../../components/loading';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Divider } from '@mui/material';

const useStyles = makeStyles({
    tableCell: {
        border: '1px solid #ccc',
        padding: '8px',
    },
});

function convert_type(type) {
    if (type === '1') return 'หญิง'
    else if (type === '2') return 'ชาย'
    else if (type === '0001') return 'สมรส'
    else if (type === '0002') return 'โสด'
    else return 'ไม่ระบุ'
}

function convert_account_status(type) {
    if (type === '10') return 'ปกติ'
    else if (type === '11') return 'ปิดบัญชี'
    else if (type === '12') return 'พักชำระหนี้ตามนโยบาลของสมาชิก'
    else if (type === '13') return 'พักชำระหนี้ตามนโยบายของรัฐ'
    else if (type === '14') return 'พักชำระหนี้เกษตรกรตามนโยบายของรัฐ'
    else if (type === '20') return 'หนี้ค้างชำระเกิน 90 วัน'
    else if (type === '30') return 'อยู่ในกระบวนการทางกฎหมาย'
    else if (type === '31') return 'อยู่ระหว่างชำระหนี้ตามคำพิพากษายอม'
    else if (type === '32') return 'ศาลพิพากษายกฟ้องเนื่องจากขาดอายุความ หรือเหตุอื่น เว้นแต่ยกฟ้องเนื่องจากความเป็นหนี้มิได้มีอยู่จริง'
    else if (type === '33') return 'ปิดบัญชีเนื่องจากตัดหนี้สูญ'
    else if (type === '40') return 'อยู่ระหว่างชำระสินเชื่อปิดบัญชี'
    else if (type === '41') return 'เจ้าของข้อมูลขอตรวจสอบรายการ'
    else if (type === '42') return 'โอนหรือขายหนี้'
    else if (type === '43') return 'โอนหนี้ขายหนี้ และชำระหนี้เสร็จสิ้น'
    else return null
}

function convert_account_type(type) {
    if (type === '01') return 'สินเชื่อเพื่อการพาณิชย์'
    else if (type === '04') return 'สินเชื่อวงเงินเบิกเกินบัญชี'
    else if (type === '05') return 'สินเชื่อบุคคล'
    else if (type === '06') return 'สินเชื่อที่อยู่อาศัย'
    else if (type === '07') return 'สินเชื่อเพื่อซื้อ/ยืมหลักทรัพย์'
    else if (type === '08') return 'สินเชื่อตั๋วเงินในประเทศ'
    else if (type === '09') return 'สินเชื่อซื้อลดตั๋วเงิน'
    else if (type === '10') return 'สินเชื่อรับซื้อตั๋วเงินสินค้าเข้า'
    else if (type === '11') return 'สินเชื่อรับซื้อตั๋วเงินสินค้าส่งออก'
    else if (type === '12') return 'สินเชื่อการรับซื้อตั๋วเงินค่าสินค้าเข้าที่ทำทรัส์รีซีท'
    else if (type === '13') return 'สินเชื่อรับซื้อตั๋วเงินอื่นๆ'
    else if (type === '14') return 'สินเชื่อเพื่อเตรียมการส่งออกตาม - L/C'
    else if (type === '15') return 'เงินชดใช้ตามภาระผูกพัน - Aval'
    else if (type === '16') return 'เงินชดใช้ตามภาระผูกพัน - '
    else if (type === '17') return 'เงินชดใช้ตามภาระผูกพัน - L/C'
    else if (type === '18') return 'เงินชดใช้ตามภาระผูกพัน - L/G'
    else if (type === '19') return 'เงินชดใช้ตามภาระผูกพันอื่นๆ'
    else if (type === '20') return 'สินเชื่อให้เช่าแบบลีสซิ่งรถยนต์'
    else if (type === '21') return 'สินเชื่อเช่าอื่นๆ'
    else if (type === '22') return 'สินเชื่อบัตรเครคิต'
    else if (type === '27') return 'สินเชื่อเช่ารถยนต์'
    else if (type === '28') return 'วงเงินสินเชื่อ'
    else if (type === '29') return 'สินเชื่อให้เช่าแบบลีสซิ่งอื่นๆ'
    else if (type === '31') return 'สินเชื่อเช่าซื้อเพื่อการเกษตรที่มีจำนวนเงินชำระต่องวดไม่คงที่'
    else if (type === '32') return 'สินเชื่อเช่าซื้อเพื่อการเกษตร'
    else if (type === '33') return 'สินเชื่อเพื่อการเกษตร'
    else if (type === '34') return 'สินเชื่อแฟ็กเตอริ่ง'
    else if (type === '35') return 'สัญญาซื้อขายล่วงหน้า'
    else if (type === '36') return 'เงินกู้สหกรณ์'
    else if (type === '37') return 'สินเชื่อรายย่อยเพื่อการประกอบอาชีพ'
    else if (type === '38') return 'การค้ำประกันเงินกู้'
    else if (type === '50') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อเพื่อการพาณิชย์'
    else if (type === '51') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อบุคคล'
    else if (type === '52') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อที่อยู่อาศัย'
    else if (type === '53') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่าแบบลีสซิ่งรถยนต์'
    else if (type === '54') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อเช่าซื้ออื่นๆ'
    else if (type === '55') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อบัตรเครคิต'
    else if (type === '56') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่ารถยนต์'
    else if (type === '57') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อให้เช่าแบบลีสซิ่งอื่นๆ'
    else if (type === '58') return 'ธุรกรรมการแปลงสินทรัพย์เป็นหลักทรัพย์-สินเชื่อวงเงินเบิกเกินบัญชี'
    else if (type === '90') return 'สินเชื่อที่มีการปรับปรุงโครงสร้างหนี้'
    else if (type === '99') return 'สินเชื่ออื่นๆ'
    else return null
}

function convert_ownershipIndicator_type(type) {
    if (type === '1') return 'กู้เดียว'
    else if (type === '2') return 'Supplementary Card'
    else if (type === '4') return 'กู้ร่วม'
    else if (type === 'A') return 'DSPT Individual'
    else if (type === 'B') return 'DSPT Supplementary Card'
    else if (type === 'C') return 'Remark Closed Account at AMC'
    else if (type === 'D') return 'DSPT Joint'
    else if (type === 'F') return 'INVS Individual'
    else if (type === 'G') return 'INVS Supplementary Card'
    else if (type === 'H') return 'INVS Authorized'
    else if (type === 'I') return 'INVS Joint'
    else return null
}

function convert_type_payment(type) {
    if (type === '000') return <><span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;0&nbsp;</span>&ensp;ไม่ค้างชำระหรือค้างชำระไม่เกิน 30 วัน</>
    else if (type === '001') return <><span style={{ backgroundColor: '#f29d38', color: '#FFFFFF' }}>&nbsp;1&nbsp;</span>&ensp;ค้างชำระ 31-60 วัน</>
    else if (type === '002') return <><span style={{ backgroundColor: '#ee6f2d', color: '#FFFFFF' }}>&nbsp;2&nbsp;</span>&ensp;ค้างชำระ 61-90 วัน</>
    else if (type === '003') return <><span style={{ backgroundColor: '#ec4726', color: '#FFFFFF' }}>&nbsp;3&nbsp;</span>&ensp;ค้างชำระ 91-120 วัน</>
    else if (type === '004') return <><span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;4&nbsp;</span>&ensp;ค้างชำระ 121-150 วัน</>
    else if (type === '005') return <><span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;5&nbsp;</span>&ensp;ค้างชำระ 151-180 วัน</>
    else if (type === '006') return <><span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;6&nbsp;</span>&ensp;ค้างชำระ 181-210 วัน</>
    else if (type === '007') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;7&nbsp;</span>&ensp;ค้างชำระ 211-240 วัน</>
    else if (type === '008') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;8&nbsp;</span>&ensp;ค้างชำระ 241-270 วัน</>
    else if (type === '009') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;9&nbsp;</span>&ensp;ค้างชำระ 271-300 วัน</>
    else if (type === '  F') return <><span style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>&nbsp;F&nbsp;</span>&ensp;ค้างชำระมากกว่า 300 วัน</>

    else if (type === '  N') return <><span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;N&nbsp;</span>&ensp;ยอดหนี้ไม่เกินวงเงิน</>
    else if (type === '  Y') return <><span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;Y&nbsp;</span>&ensp;ยอดหนี้เกินวงเงิน</>
}

function icon(type) {
    if (type === '000') return <span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;0&nbsp;</span>
    else if (type === '001') return <span style={{ backgroundColor: '#f29d38', color: '#FFFFFF' }}>&nbsp;1&nbsp;</span>
    else if (type === '002') return <span style={{ backgroundColor: '#ee6f2d', color: '#FFFFFF' }}>&nbsp;2&nbsp;</span>
    else if (type === '003') return <span style={{ backgroundColor: '#ec4726', color: '#FFFFFF' }}>&nbsp;3&nbsp;</span>
    else if (type === '004') return <span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;4&nbsp;</span>
    else if (type === '005') return <span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;5&nbsp;</span>
    else if (type === '006') return <span style={{ backgroundColor: '#bc271a', color: '#FFFFFF' }}>&nbsp;6&nbsp;</span>
    else if (type === '007') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;7&nbsp;</span>
    else if (type === '008') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;8&nbsp;</span>
    else if (type === '009') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;9&nbsp;</span>
    else if (type === '  F') return <span style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>&nbsp;F&nbsp;</span>

    else if (type === '  N') return <span style={{ backgroundColor: '#489b71', color: '#FFFFFF' }}>&nbsp;N&nbsp;</span>
    else if (type === '  Y') return <span style={{ backgroundColor: '#8c1a11', color: '#FFFFFF' }}>&nbsp;Y&nbsp;</span>
}

export default function App() {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [data, setData] = useState()
    const { contact_id } = useParams()
    // const { detail } = useParams()

    const getToken = () => {
        let token = localStorage.getItem('token_org')
        return token
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await ncbHttpServices.summarize(contact_id, getToken());
                console.log(res);
                const summaryData = res.data;
                setData(summaryData);

                const logRes = await ncbHttpServices.addlog({ type: 'overall', contact_id: contact_id, ...summaryData.calculated_data.MSCW });
                console.log(logRes);
            } catch (error) {
                // Handle any errors here
                console.error('error', error);
            }
        }

        fetchData();
    }, []);

    function alert1() {
        if (data.calculated_data.other.alert1) {
            return (
                <Alert severity="error" sx={{ marginBottom: '1rem' }}>
                    <AlertTitle>แจ้งเตือน!</AlertTitle>
                    <Typography variant="body1">ลูกค้าท่านนี้ มียอดหนี้ที่ค้างชำระเกิน 20% ของยอดหนี้ทั้งหมด</Typography>
                </Alert>
            )
        }
    }

    function alert2() {
        if (data.calculated_data.other.alert2) {
            return (
                <Alert severity="error" sx={{ marginBottom: '1rem' }}>
                    <AlertTitle>แจ้งเตือน!</AlertTitle>
                    <Typography variant="body1">ลูกค้าท่านนี้ มีประวัติ สถานะบัญชี 43: โอนหรือขายหนี้และชำระหนี้เสร็จสิ้น</Typography>
                </Alert>
            )
        }
    }

    function alert3() {
        if (data.calculated_data.other.alert3) {
            return (
                <Alert severity="error" sx={{ marginBottom: '1rem' }}>
                    <AlertTitle>แจ้งเตือน!</AlertTitle>
                    <Typography variant="body1">ลูกค้าท่านนี้ มีประวัติ สถานะบัญชี 42: โอนหรือขายหนี้</Typography>
                </Alert>
            )
        }
    }

    function alert4() {
        if (data.calculated_data.MSCW.value_4.value_4_2.value_4_2_1 <= 6) {
            return (
                <Alert severity="error" sx={{ marginBottom: '1rem' }}>
                    <AlertTitle>แจ้งเตือน!</AlertTitle>
                    <Typography variant="body1">ไม่ผ่านเกณฑ์: ลูกค้าท่านนี้ไม่มีบัญชีที่มีจำนวนเดือนที่ไม่เสียสูงกว่า 6 เดือน</Typography>
                </Alert>
            )
        }
    }

    if (!data) {
        return <Loading /> // Display a loading message or spinner
    }
    return (
        <>
            <div style={{ margin: '5% 10%' }}>
                <SideBar />

                <br />
                {alert1()}
                {alert2()}
                {alert3()}
                {alert4()}
                <Paper elevation={3} >
                    <div style={{ padding: '2rem' }}>
                        <Typography variant="h4">สรุปข้อมูล NCB</Typography>
                        <br />
                        <Typography variant="body1">วันที่สืบค้น: {data.data?.header?.enquityDate}&emsp;ชื่อ-นามสกุล: {data.data?.name?.N01?.firstName}{' '}{data.data?.name?.N01?.familyName1}&emsp;เลขบัตรประชาชน: {data.data?.id?.ID1?.IDNumber}&emsp;วันเกิด: {data.data?.name?.N01?.dateOfBirth}&emsp;เพศ: {convert_type(data.data?.name?.N01?.gender)}&emsp;สถานภาพ: {convert_type(data.data?.name?.N01?.maritalStatus)}&emsp;อายุ: {data.calculated_data?.user?.age}</Typography>

                        <Divider sx={{ margin: '40px 0' }} />

                        <Typography gutterBottom variant="h6" >ข้อมูลประกอบการตัดสินใจ</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined" sx={{ borderColor: data.calculated_data.ncb_score.ncb_score < 616 ? 'red' : 'green' }}>
                                    <CardContent sx={{ color: data.calculated_data.ncb_score.ncb_score < 616 ? 'red' : 'green' }}>
                                        <Typography gutterBottom variant="body1" component="div">NCB Score สรุป</Typography>
                                        <Typography variant="h5" align='right'>{parseInt(data.calculated_data.ncb_score.ncb_score)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined" sx={{ borderColor: data.calculated_data.other_detail.amountPastDue > 0 ? 'red' : 'green' }}>
                                    <CardContent sx={{ color: data.calculated_data.other_detail.amountPastDue > 0 ? 'red' : 'green' }}>
                                        <Typography gutterBottom variant="body1" component="div" >ยอดหนี้ที่เกินกำหนดชำระ</Typography>
                                        <Typography variant="h5" align='right' >{(data.calculated_data.other_detail.amountPastDue || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้คงเหลือ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.bad_debt_history.amountOwed || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >NCB Score</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">NCB Score 1</Typography>
                                        <Typography variant="h5" align='right'>{isNaN(parseInt(data.calculated_data.user.ncb_score_1)) ? '-' : parseInt(data.calculated_data.user.ncb_score_1)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">NCB Score 2</Typography>
                                        <Typography variant="h5" align='right'>{isNaN(parseInt(data.calculated_data.user.ncb_score_2)) ? '-' : parseInt(data.calculated_data.user.ncb_score_2)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">NCB Score 3</Typography>
                                        <Typography variant="h5" align='right'>{isNaN(parseInt(data.calculated_data.user.ncb_score_3)) ? '-' : parseInt(data.calculated_data.user.ncb_score_3)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">NCB Score 4</Typography>
                                        <Typography variant="h5" align='right'>{isNaN(parseInt(data.calculated_data.user.ncb_score_4)) ? '-' : parseInt(data.calculated_data.user.ncb_score_4)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">PD 1</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.user.pd_1 === undefined) ? '-' : data.calculated_data.user.pd_1}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">PD 2</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.user.pd_2 === undefined) ? '-' : data.calculated_data.user.pd_2}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">PD 3</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.user.pd_3 === undefined) ? '-' : data.calculated_data.user.pd_3}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">PD 4</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.user.pd_4 === undefined) ? '-' : data.calculated_data.user.pd_4}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">NCB Score สรุป</Typography>
                                        <Typography variant="h5" align='right'>{parseInt(data.calculated_data.ncb_score.ncb_score)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.ncb_score.ncb_pico_score}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.ncb_score.ncb_pico_score_weight}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.ncb_score.ncb_pico_score_result).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">PD สรุป</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.ncb_score.ncb_odds}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >รายละเอียดบัตรเครคิต</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">อายุเครคิต</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.credit_card_detail.credit_age}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนเดือนที่ไม่เสีย</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_4.value_4_2.value_4_2_1}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_4.value_4_2.value_4_2_2}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_4.value_4_2.value_4_2_3}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.MSCW.value_4.value_4_2.value_4_2_4).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">อายุบัญชีที่ Active ยาวนานที่สุด</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_4.value_4_3.value_4_3_1}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_4.value_4_3.value_4_3_2}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_4.value_4_3.value_4_3_3}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.MSCW.value_4.value_4_3.value_4_3_4).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">บัญชีที่เสีย 1-3 งวด</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.credit_card_detail.bad_account_1to3_installments}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้คงเหลือที่เสีย 1-3 งวด</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_detail.bad_debt_balance_1to3_installments || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้คงเหลือที่เสีย 2 งวดขึ้นไป</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_detail.bad_debt_balance_more_than_4_installments || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">บัญชีที่เสีย 2 งวดขึ้นไป</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.credit_card_detail.bad_account_more_than_2_installments}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                {/* <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.credit_card_detail.bad_account_more_than_4_installments_pico_score}</Typography>
                                    </CardContent>
                                </Card> */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.credit_card_detail.bad_account_more_than_4_installments_pico_score_weight}{'%'}</Typography>
                                    </CardContent>
                                </Card> */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_detail.bad_account_more_than_4_installments_pico_score_result).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card> */}
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนบัญชีทั้งหมด</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_detail.total_account || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนบัญชีที่ Active</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_detail.total_active_account || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนบัญชีที่ Active (กู้ร่วม)</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_detail.total_active_joint_account || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >รายละเอียดบ้าน/รถยนต์</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนสินเชื่อบ้าน</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.home_car_loan_detail.total_home_loan || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid> <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้คงเหลือบ้าน</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.home_car_loan_detail.home_debt || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนสินเชื่อรถ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.home_car_loan_detail.total_car_loan || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้คงเหลือรถ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.home_car_loan_detail.car_debt || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >ประวัติหนี้เสีย</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ประวัติเสียกับ NCB</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.bad_debt_history.bad_history_with_ncb}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid> <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.bad_debt_history.bad_history_with_ncb_pico_score}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.bad_debt_history.bad_history_with_ncb_pico_score_weight}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.bad_debt_history.bad_history_with_ncb_pico_score_result).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วันที่เสียครั้งแรก</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.bad_debt_history.first_owed}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid> <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ช่วงเวลาที่เริ่มแก้ไข</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.bad_debt_history.started_edit}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">แก้ไขเสร็จสิ้นเมื่อไร</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.bad_debt_history.finished_edit}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ปล่อยเสียมากี่เดือน</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.bad_debt_history.total_left_months || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >บัญชีที่เปิดภายใน 1 ปี</Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>ลำดับบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>วันที่เปิดบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '50%', fontSize: '14px' }}>ประเภทบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>วงเงิน</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>ผ่อนชำระ</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>ภาระหนี้คงเหลือ</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Object.values(data.calculated_data.open_account_in_1_year.rows).map((value, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{value.index + 1}</TableCell>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{value.dateAccountOpened.slice(0, 10)}</TableCell>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{(value.accountType || 0).toLocaleString()}</TableCell>
                                                <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(value.creditLimit || 0).toLocaleString()}{'.00'}</TableCell>
                                                <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(value.installmentAmount || 0).toLocaleString()}{'.00'}</TableCell>
                                                <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(value.amountOwed || 0).toLocaleString()}{'.00'}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableCell className={classes.tableCell} align='center' colSpan={3} sx={{ fontSize: '14px' }}>รวม</TableCell>
                                        <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(data.calculated_data.open_account_in_1_year.footer.creditLimt || 0).toLocaleString()}{'.00'}</TableCell>
                                        <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(data.calculated_data.open_account_in_1_year.footer.installmentAmount || 0).toLocaleString()}{'.00'}</TableCell>
                                        <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(data.calculated_data.open_account_in_1_year.footer.amountOwed || 0).toLocaleString()}{'.00'}</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>

                        <br />
                        <Typography gutterBottom variant="h6" >บัญชีที่ปิดภายใน 1 ปี</Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>ลำดับบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>วันที่ปิดบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>วันที่เปิดบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '40%', fontSize: '14px' }}>ประเภทบัญชี</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>วงเงิน</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>ผ่อนชำระ</TableCell>
                                        <TableCell className={classes.tableCell} align='center' sx={{ fontWeight: 'bold', width: '10%', fontSize: '14px' }}>ภาระหนี้คงเหลือ</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Object.values(data.calculated_data.close_account_in_1_year.rows).map((value, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{value.index + 1}</TableCell>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{value.dateAccountClosed.slice(0, 10)}</TableCell>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{value.dateAccountOpened.slice(0, 10)}</TableCell>
                                                <TableCell className={classes.tableCell} align='center' sx={{ fontSize: '14px' }}>{(value.accountType || 0).toLocaleString()}</TableCell>
                                                <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(value.creditLimit || 0).toLocaleString()}{'.00'}</TableCell>
                                                <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(value.installmentAmount || 0).toLocaleString()}{'.00'}</TableCell>
                                                <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(value.amountOwed || 0).toLocaleString()}{'.00'}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableCell className={classes.tableCell} align='center' colSpan={4} sx={{ fontSize: '14px' }}>รวม</TableCell>
                                        <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(data.calculated_data.close_account_in_1_year.footer.creditLimt || 0).toLocaleString()}{'.00'}</TableCell>
                                        <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(data.calculated_data.close_account_in_1_year.footer.installmentAmount || 0).toLocaleString()}{'.00'}</TableCell>
                                        <TableCell className={classes.tableCell} align='right' sx={{ fontSize: '14px' }}>{(data.calculated_data.close_account_in_1_year.footer.amountOwed || 0).toLocaleString()}{'.00'}</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>

                        <br />
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดส่วนต่างระหว่างบัญชีเปิด-ปิด</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.difference || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ภาระหนี้ใหม่สุทธิ 6 เดือนล่าสุด</Typography>
                                        <Typography variant="h5" align='right'>{(data?.calculated_data?.MSCW?.value_5?.value_5_5?.value_5_5_1 || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company Score</Typography>
                                        <Typography variant="h5" align='right'>{(data?.calculated_data?.MSCW?.value_5?.value_5_5?.value_5_5_2 || 0)}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{`${(data?.calculated_data?.MSCW?.value_5?.value_5_5?.value_5_5_3 || 0)}%`}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{`${(data?.calculated_data?.MSCW?.value_5?.value_5_5?.value_5_5_4 || 0).toFixed(2)}%`}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >ประวัติสืบค้น NCB</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ภาระหนี้ใหม่ต่อรายได้รวม</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data.calculated_data.MSCW.value_5.value_5_1.value_5_1_1 !== 0 ? (
                                                `${(data.calculated_data.MSCW.value_5.value_5_1.value_5_1_1 || 0).toFixed(2).toLocaleString()} เท่า`
                                            ) : (
                                                'ไม่มีข้อมูลรายได้รวม'
                                            )}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company Score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_5.value_5_1.value_5_1_2}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.MSCW.value_5.value_5_1.value_5_1_3}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.MSCW.value_5.value_5_1.value_5_1_4 || 0).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนการขอสินเชื่อใหม่</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.user.new_loan}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company Score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.user.new_loan_score}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.user.new_loan_score_weight}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.user.new_loan_score_result).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">แนวโน้มการขอสินเชื่อใหม่</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.user.trend[0]}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                {/* <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company Score</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.user.trend_score}</Typography>
                                    </CardContent>
                                </Card> */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.user.trend_score_weight}{'%'}</Typography>
                                    </CardContent>
                                </Card> */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.user.trend_score_result).toFixed(2)}{'%'}</Typography>
                                    </CardContent>
                                </Card> */}
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ขอสินเชื่อใหม่ (3 เดือน)</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.enquiry_ncb_history.req_new_loan_3}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ขอสินเชื่อใหม่ (2 เดือน)</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.enquiry_ncb_history.req_new_loan_2}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ขอสินเชื่อใหม่ (1 เดือน)</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.enquiry_ncb_history.req_new_loan_1}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}></Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ทบทวนสินเชื่อ (3 เดือน)</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.enquiry_ncb_history.revive_loan_3}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ทบทวนสินเชื่อ (2 เดือน)</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.enquiry_ncb_history.revive_loan_2}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ทบทวนสินเชื่อ (1 เดือน)</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.enquiry_ncb_history.revive_loan_1}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >ข้อมูลอื่นๆ</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วันที่ผิดนัดชำระ</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.other_detail.defaultDate}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วันที่ชำระหนี้ล่าสุด</Typography>
                                        <Typography variant="h5" align='right'>{data.calculated_data.other_detail.dateOflastPayment}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้คงเหลือ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.bad_debt_history.amountOwed || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดหนี้ที่เกินกำหนดชำระ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.other_detail.amountPastDue || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ความแข็งแรงของนายจ้าง</Typography>
                                        <Typography variant="h5" align='right'>{data?.calculated_data?.MSCW?.value_2?.value_2_5?.value_2_5_1 || 'ไม่มีข้อมูล'}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_2?.value_2_5?.value_2_5_2 !== undefined
                                                ? data.calculated_data.MSCW.value_2.value_2_5.value_2_5_2
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_2?.value_2_5?.value_2_5_3 !== undefined
                                                ? `${data.calculated_data.MSCW.value_2.value_2_5.value_2_5_3}%`
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_2?.value_2_5?.value_2_5_4 !== undefined
                                                ? `${(data.calculated_data.MSCW.value_2.value_2_5.value_2_5_4 || 0).toFixed(2).toLocaleString()}%`
                                                : 'N/A'
                                            }
                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนครั้งที่มีประวัติเสีย</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_4?.value_4_6?.value_4_6_1 !== undefined
                                                ? data.calculated_data.MSCW.value_4.value_4_6.value_4_6_1
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_4?.value_4_6?.value_4_6_2 !== undefined
                                                ? data.calculated_data.MSCW.value_4.value_4_6.value_4_6_2
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_4?.value_4_6?.value_4_6_3 !== undefined
                                                ? `${data.calculated_data.MSCW.value_4.value_4_6.value_4_6_3}%`
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>
                                            {typeof data?.calculated_data?.MSCW?.value_4?.value_4_6?.value_4_6_4 === 'number'
                                                ? `${(data.calculated_data.MSCW.value_4.value_4_6.value_4_6_4 || 0).toFixed(2).toLocaleString()}%`
                                                : 'N/A'
                                            }
                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ผ่อนหนี้ NCB/รายได้รวม</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_3?.value_3_9?.value_3_9_1 !== undefined
                                                ? (data.calculated_data.MSCW.value_3.value_3_9.value_3_9_1 === 0 ? '0' : (data.calculated_data.MSCW.value_3.value_3_9.value_3_9_1 || 0).toFixed(2).toLocaleString())
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Company score</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_3?.value_3_9?.value_3_9_2 !== undefined
                                                ? data.calculated_data.MSCW.value_3.value_3_9.value_3_9_2
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Weight</Typography>
                                        <Typography variant="h5" align='right'>
                                            {data?.calculated_data?.MSCW?.value_3?.value_3_9?.value_3_9_3 !== undefined
                                                ? `${data.calculated_data.MSCW.value_3.value_3_9.value_3_9_3}%`
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">Result</Typography>
                                        <Typography variant="h5" align='right'>
                                            {typeof data?.calculated_data?.MSCW?.value_3?.value_3_9?.value_3_9_4 === 'number'
                                                ? `${(data.calculated_data.MSCW.value_3.value_3_9.value_3_9_4 || 0).toFixed(2).toLocaleString()}%`
                                                : 'N/A'
                                            }
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >ข้อมูลกลุ่มบัญชีบัตรเครคิต</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนสินเชื่อหมุนเวียน</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.amount || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วงเงินหมุนเวียนทั้งหมด</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.total_revolving || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วงเงินที่ใช้ไป</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.total_spent || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">ยอดจ่ายขึ้นต่ำ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.low_payout || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">หมุนเวียนคงเหลือ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.revolving_current || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วงเงินที่เบิกใช้ได้</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.amount_can_withdrawn || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">จำนวนบัญชีที่ Active</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.total_active_account || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">วงเงินที่เบิกใช้ได้หลังจ่ายขึ้นต่ำ</Typography>
                                        <Typography variant="h5" align='right'>{(data.calculated_data.credit_card_information.amount_can_withdrawn_after_deduction || 0).toLocaleString()}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography gutterBottom variant="h6" >ข้อมูลสินเชื่อ (ย้อนหลัง 3 เดือน)</Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>วัน/เวลา</TableCell>
                                        <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>ผู้ให้สินเชื่อ</TableCell>
                                        <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>ประเภทสถาบันการเงิน</TableCell>
                                        <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>วัตถุประสงค์การสืบค้น</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Object.values(data.calculated_data.loan_information_last_3_months.rows).map((value, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align='center' sx={{ fontSize: '14px' }}>{value.date}</TableCell>
                                                <TableCell align='center' sx={{ fontSize: '14px' }}>{value.member}</TableCell>
                                                <TableCell align='center' sx={{ fontSize: '14px' }}>{value.type_of_financial}</TableCell>
                                                <TableCell align='center' sx={{ fontSize: '14px' }}>{value.purpose}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br />
                        <br />
                        {Object.values(data.data.account).map((value, index) => {
                            return (
                                <Accordion expanded={expanded === 'panel' + index} key={index} onChange={handleChange('panel' + index)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-content"
                                        id="panel-header"
                                    >
                                        <Typography sx={{ width: '10%', fontWeight: 'bold', flexShrink: 0 }}>
                                            ข้อมูลบัญชี {index + 1}
                                        </Typography>
                                        <Typography sx={{ width: '30%', color: 'text.secondary' }} align='left'>วงเงินสินเชื่อ/วงเงินบัตรเครคิตรวม: {parseInt(value.creditLimit || 0).toLocaleString()}</Typography>
                                        <Typography sx={{ width: '30%', color: 'text.secondary' }} align='left'>ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้รวม: {parseInt(value.amountOwed || 0).toLocaleString()}</Typography>
                                        <Typography sx={{ width: '30%', color: 'text.secondary' }} align='left'>ยอดหนี้ที่เกินกำหนด: {parseInt(value.amountPastDue || 0).toLocaleString()}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            <Grid item xs={3}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>ผู้ให้สินเชื่อ</p>
                                                <p className='small-text'>
                                                    ผู้ให้สินเชื่อ:&emsp;{value.memberShortName}<br />
                                                    ประเภทสมาชิก:<br />
                                                    ข้อมูล ณ วันที่:&emsp;{value.asOfDate}<br />
                                                </p>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>บัญชี</p>
                                                <p className='small-text'>
                                                    บัญชี:<br />
                                                    ประเภทสินเชื่อ:&emsp;{convert_account_type(value.accountType)}<br />
                                                    เปิดบัญชี:&emsp;{value.dateAccountOpened}<br />
                                                    วันที่ปรับปรุงโครงสร้างหนี้:&emsp;{value.dateOfLastDebtRestructure}<br />
                                                    บัญชีที่ปิด:&emsp;{value.dateAccountClosed}<br />
                                                </p>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>รายละเอียด</p>
                                                <p className='small-text'>
                                                    ประเภทของบัตรเครคิต: <br />
                                                    ลักษณะการกู้:&ensp;{convert_ownershipIndicator_type(value.ownershipIndicator)}<br />
                                                    จำนวนผู้กู้ร่วม:&emsp;{value.numberOfCoborrowers}<br />
                                                    วงเงินสินเชื่อ/วงเงินบัตรเครคิต:&emsp;{parseInt(value.creditLimit || 0).toLocaleString()} {(value.creditTypeFlag === undefined) ? null : '(' + value.creditTypeFlag + ')'}<br />
                                                    ยอดผ่อนชำระในแต่ละงวด/ยอดชำระในแต่ละงวด:&emsp;{'THB'}&ensp;{parseInt(value.installmentAmount || 0).toLocaleString()}<br />
                                                    {value.installmentFrequency === '9' ? 'รายปี' : 'รายเดือน'}<br />
                                                    จำนวนงวดที่ต้องผ่อนชำระ:&emsp;{value.installmentNumberOfPayment}<br />
                                                    ยอดขั้นตำ่ที่ต้องผ่อนชำระ(%):&emsp;{value.percentPayment}{'%'}<br />
                                                    ยี่ห้อ:&emsp;{value.unitMake}<br />
                                                    รุ่น:&emsp;{value.unitModel}<br />
                                                    หลักประกัน:<br />
                                                    {value.collateral1 === undefined ? undefined : <span>1:&emsp;{value.collateral1}</span>}<br />
                                                    {value.collateral2 === undefined ? undefined : <span>2:&emsp;{value.collateral2}</span>}<br />
                                                    {value.collateral3 === undefined ? undefined : <span>3:&emsp;{value.collateral3}</span>}
                                                </p>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้</p>
                                                <p className='small-text'>
                                                    ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้:&emsp;<br />{'THB'}&ensp;{parseInt(value.amountOwed || 0).toLocaleString()}<br />
                                                    ยอดหนี้ที่เกินกำหนดชำระ:&emsp;{parseInt(value.amountPastDue || 0).toLocaleString()}<br />
                                                    วันที่ชำระหนี้ล่าสุด:&emsp;{value.dateOflastPayment}<br />
                                                    วันที่เริ่มมีการผิดนัดชำระ:&emsp;<br />{value.defaultDate}<br />
                                                </p>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }} align='center'>ข้อมูลการค้างชำระ (36 เดือน)</p>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={4} align='center'>{'>'}30 <br />วัน: {data.calculated_data.payment_history.payment_history_detail3[index][0]}</Grid>
                                                    <Grid item xs={4} align='center'>{'>'}60 <br />วัน: {data.calculated_data.payment_history.payment_history_detail3[index][1]}</Grid>
                                                    <Grid item xs={4} align='center'>{'>'}90 <br />วัน: {data.calculated_data.payment_history.payment_history_detail3[index][2]}</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>ประวัติการชำระ (36 เดือน)</p>
                                                <p>
                                                    {Object.values(data.calculated_data.payment_history.payment_history_detail1[index][1]).map((value, index) => {
                                                        return <span key={index} style={{ paddingLeft: '3px' }}>{icon(value[0])}</span>
                                                    })}
                                                </p>
                                                <p style={{ marginTop: '-1rem' }} >
                                                    {Object.values(data.calculated_data.payment_history.payment_history_detail2[index]).map((value, index) => {
                                                        return <span key={index} className='small-icon' style={{ paddingLeft: '6.5px' }}>&nbsp;{value}</span>
                                                    })}
                                                </p>
                                            </Grid>

                                            <br />
                                            <Grid item xs={12}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>สถานะ</p>
                                                <p className='small-text'>สถานะบัญชี:&emsp;{value.accountStatus}: {convert_account_status(value.accountStatus)}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>วัตถุประสงค์</p>
                                                <p className='small-text'>วัตถุประสงค์:&emsp;{value.loanObjective}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className='small-text' style={{ fontWeight: 'bold' }}>ประวัติการชำระ</p>
                                                <TableContainer>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>ข้อมูล ณ วันที่</TableCell>
                                                                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>วงเงินสินเชื่อ/วงเงินบัตรเครคิต</TableCell>
                                                                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>ลักษณะการชำระ/จำนวนวันค้างชำระ</TableCell>
                                                                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>ยอดหนี้คงเหลือ/ยอดวงเงินที่ใช้</TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {Object.values(data.calculated_data.table_detail).map((table, index) => {
                                                                if (value.segment === table.segment) {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell align='center' sx={{ fontSize: '14px' }}>
                                                                                {table.asOfDate.slice(0, 10)}
                                                                            </TableCell>
                                                                            <TableCell align='center' sx={{ fontSize: '14px' }}>
                                                                                {(table.creditLimit === undefined) ? '-' : ('THB  ' + parseInt(table.creditLimit || 0).toLocaleString())}
                                                                            </TableCell>
                                                                            <TableCell align='left' sx={{ paddingLeft: '1.5rem', fontSize: '14px' }}>
                                                                                {convert_type_payment(table.overDueMonths)}
                                                                            </TableCell>
                                                                            <TableCell align='center' sx={{ fontSize: '14px' }}>
                                                                                {'THB'}&ensp;{parseInt(table.amountOwed || 0).toLocaleString()}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                </Paper>
            </div>
        </>

    )
}