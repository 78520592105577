import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import UploadTable from './upload_table.js'

// components
import Loading from '../../components/loading.js'
import AlertDialog from './alertDialog.js';
import * as statementHttpServices from '../../services/statement_service.js';

export default function App() {
    const { contact_id, citizen_id } = useParams();
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchData = async () => {
        try {
            console.log(citizen_id);
            const { data } = await statementHttpServices.getStatements(contact_id);

            const keyInStatements = data.data.keyinStatements.map((item, index) => {
                const obj = {
                    "statement_id": item._id,
                    "contact_id": item.contact_id,
                    "bank": item.data[0].account_info.bank.en_name,
                    "account_name": "-",
                    "account_number": "-",
                    "account_period": "-",
                    "statement_type": "KEY IN",
                    "status": "DONE"
                }
                return obj
            });

            const ocrStatements = data.data.ocrStatements.map((item, index) => {
                const obj = {
                    "file_id": parseInt(item.callback.split("id=")[1], 10),
                    "statement_id": item._id,
                    "contact_id": item.contact_id,
                    "bank": item.bank,
                    "account_name": item?.data?.account_info?.account_name,
                    "account_number": item?.data?.account_info?.account_number,
                    "account_period": item?.data?.account_info?.account_period,
                    "statement_type": "OCR",
                    "filename": item.files[0],
                    "status": item.status
                }
                return obj
            });

            const mergedArray = [...ocrStatements, ...keyInStatements];

            const mergedWithIds = mergedArray.map((item, index) => ({
                ...item,
                id: index + 1,
            }));

            setRows(mergedWithIds);

            // dga statememt
            const dgaResponse = await statementHttpServices.dgaStatements({
                contact_id: citizen_id,
            });

            console.log('dgaResponse', dgaResponse)

            const rowsWithId = dgaResponse.data.map((row, index) => ({
                id: index + 1,
                ...row,
            }));

            const filteredRows = rowsWithId.filter((row) => !ocrStatements.some((statement) => statement.file_id === row.file_id && statement.status !== 'FAILED'));
            console.log("filteredRows", filteredRows);
            setRows2(filteredRows);

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            // Handle specific errors as needed
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleClickEdit = (row) => {
        if (row.statement_type === 'KEY IN') {
            window.location.href = '/statement/edit_manual/' + row.statement_id
        } else {
            window.location.href = '/statement/edit_ocr/' + row.statement_id
        }
    }

    const handleClickDelete = async (rows) => {
        const result = window.confirm('คุณต้องการลบหรือไม่?');
        if (result) {
            console.log('User clicked OK');

            try {
                const { statement_type, statement_id } = rows;

                if (statement_type === "OCR") {
                    await statementHttpServices.delStatementOCR(statement_id);
                } else {
                    await statementHttpServices.delStatementKeyin(statement_id);
                }

                window.location.reload();
            } catch (error) {
                console.error('Error deleting statement:', error);
            }
        } else {
            console.log('User clicked Cancel');
        }

    };

    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'contact_id',
            headerName: 'Contact ID',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'account_name',
            headerName: 'ชื่อ-สกุล',
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'account_number',
            headerName: 'หมายเลขบัญชี',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'account_period',
            headerName: 'รอบบัญชี',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'bank',
            headerName: 'ธนาคาร',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.bank.toUpperCase()
        },
        {
            field: 'statement_type',
            headerName: 'ประเภท',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'สถานะเอกสาร',
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
        },
        // {
        //     field: 'result',
        //     headerName: 'ผลการพิจารณา',
        //     width: 250,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: "statement",
            headerName: "Edit File Statement",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) =>
                <Button onClick={() => handleClickEdit(row)}>
                    EDIT
                </Button>,
        },
        {
            field: "del_statement",
            headerName: "Delete File Statement",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) =>
                <Button onClick={() => handleClickDelete(row)} sx={{ color: 'red' }}>
                    DELETE
                </Button>,
        },
    ];

    if (isLoading) {
        return <Loading />; // Display loading message or spinner
    }

    return (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Statement Files List</Typography>
                </Grid>
            </Grid>

            <Paper elevation={3} >
                <Grid container padding={2} marginBottom={1}>
                    <Grid item xs>
                        <Typography variant='h6' >รายการไฟล์ Statement</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => window.location.href = '/statement/upload_manual/' + contact_id}>manual key in</Button>
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <Button variant="contained" onClick={() => setOpen(true)}>upload pdf</Button>
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <Button variant="contained" onClick={() => window.location.href = '/statement/analysis_preview/' + contact_id}>statement analysis</Button>
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <Button variant="contained" onClick={() => window.location.href = '/statement/loan_analysis_preview/' + contact_id}>loan statement analysis</Button>
                    </Grid>
                </Grid>
                <Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}

                    />
                </Box>
            </Paper>
            <br />
            <UploadTable rows={rows2} contact_id={contact_id} />

            <AlertDialog open={open} handleClose={handleClose} handleOpen={handleOpen} />
        </div>
    )
}