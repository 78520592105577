import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import SideBar from '../dashboard.js';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { confirmAlert } from 'react-confirm-alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectCredit from '../../services/direct_credit.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { checkPermission } from '../../utils/permission_utils.js';
import { generatePdfDocument } from '../../reports/direct_debit_report.js';
import ExcelExport from '../../reports/direct_debit_excel.js'
const moment = require('moment');

export default function App() {
    let token_org = localStorage.getItem('token_org')
    let token = localStorage.getItem('token')

    let decode;
    if (token === null) {
        window.location.href = '/login';
    } else {
        decode = jwt_decode(token);
    }
    const [open, setOpen] = useState(true);
    const [rows_tran, setRowsTran] = useState([]);
    const [selected_row, setSelectedRow] = useState([]);
    const [from_date, setFromDate] = useState(null);
    const [end_date, setEndDate] = useState(null)
    const [excel_data, setExcelData] = useState([])
    const [excel_data2, setExcelData2] = useState([])


    const location = useLocation();
    const has_permis = checkPermission(location.pathname, decode);

    useEffect(() => {
        if (!has_permis) {
            window.location.href = '/404';
        }
        loadAwaitList();
    }, [excel_data])

    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const formatNumberWithCommas2 = (number) => {
        return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const loadAwaitList = () => {
        setOpen(true);
        let object = {
            start_date: from_date,
            end_date: from_date
        }
        DirectCredit.getFinishTransfer(object, token_org)
            .then((res) => {
                const list_data = res.data.data;
                console.log(list_data)
                const transfer = [];
                list_data.map((item, index) => {
                    let date = moment(item.transfer_date).format('YYYY-MM-DD')
                    let bureau = 13
                    let gb_fee = 4
                    if(date === '2023-10-20'){
                        bureau = 0
                    }
                    if( item.contact_id === '1521751'){
                        bureau = 13
                    }
                    if(
                        item.contact_id === '1500001'
                        || item.contact_id === '27751'
                        || item.contact_id === '1424351'
                        || item.contact_id === '1597601'
                        || item.contact_id === '1546851'
                        || item.contact_id === '1662501'
                        || item.contact_id === '1677251' 
                        || item.contact_id === '1547501'
                        || item.contact_id === '1662551'
                        || item.contact_id === '1616451'
                      ){
                        gb_fee = 0
                      }
                    transfer.push(
                        {
                            "id": index + 1,
                            "fullname": `${item.name}`,
                            "contact_id": item.contact_id,
                            "offer_limit": formatNumberWithCommas2(item.offer_limit),
                            "duty_fee": item.duty_fee,
                            "bureau": item.waive_type === "NONE" || item.waive_type === "FEE" ? 13 : 0 ,
                            "advancepaid_month1": item.advancepaid_month1 === 'no' ? '0' : formatNumberWithCommas(item.advance_paid_1),
                            "advancepaid_month2": item.advancepaid_month2 === 'no' ? '0' : formatNumberWithCommas(item.advance_paid_2),
                            "close_payment": item.close_payment === undefined ? "-" : formatNumberWithCommas(item.close_payment),
                            "net_transfer": formatNumberWithCommas(item.net_transfer),
                            "bank_name": `${item.direct_debit !== 0 ? (item.bank_name === 'null' ? '-' : item.bank_name) : (item.bank_name === 'null' ? '-' : item.bank_name)}`,
                            "account_no": `${item.direct_debit !== 0 ? (item.accountno === 'null' ? '-' : item.accountno) : (item.accountno === 'null' ? '-' : item.accountno)}`,
                            "case_maker": item.case_maker === undefined ? '-' : item.case_maker,
                            "direct_debit": item.direct_debit === 1 ? 'เข้าร่วม' : 'ไม่เข้าร่วม',
                            "transfer_date": item.transfer_date,
                            "transferReferenceNo": item.transferReferenceNo,
                            "referenceNo": item.referenceNo,
                            "d_loan": item.d_loan,
                            "fee": item.waive_type === "NONE" || item.waive_type === "BUREAU" ? 4 : 0 ,
                            "policygroup" : item.policygroup,
                            "old_policy_group" : item.old_policy_group,
                            "waive_desc" : item.waive_type === 'NONE' ? 'ไม่ยกเว้น' : 'ยกเว้น'
                            
                        }
                    )
                });
                setRowsTran(transfer);
                setOpen(false);
            })
    }

    const handleSelectedChange = (newSelection) => {
        //console.log(newSelection.selectionModel);
        setSelectedRow(newSelection);
    };

    const handleExportExcelButton = () => {

        let data = {
            transfer_date: from_date
        }
        console.log(data)
        if (from_date !== null) {
            DirectCredit.GetExcelData(data)
                .then(res => {
                    const excel_datasets = res.data.data;
                    console.log(excel_datasets)
                    const list_datas = [];
                    excel_datasets.map(item => {
                        list_datas.push({
                            Policy_no: item.Policy_no,
                            Amount: item.Amount,
                            Word: item.Word,
                            Interest_rate: item.Interest_rate,
                            Fee_rate: item.Fee_rate,
                            cal_use: '',
                            PPP: item.PPP,
                            total_payback: item.total_payback,
                            Period_pay: 'เดือน',
                            pay_no: item.pay_no,
                            plub_rate: '0',
                            Plub_percent: 'FALSE',
                            P_Status: 'TRUE',
                            Total_month: '99',
                            P_note: '',
                            Policy_date: item.Policy_date,
                            End_date: item.End_date,
                            Close_date: '',
                            EN_Followup: item.EN_Followup,
                            Payan1: '',
                            Payan2: '',
                            Payan3: '',
                            service_one: '',
                            service_one_value: '',
                            service_one_add: 'FALSE',
                            fee_one: '',
                            fee_one_add: 'FALSE',
                            fee_one_value: '0',
                            Ku_request: item.Ku_request,
                            Ku_code: item.Ku_code,
                            Ku_name: item.Ku_name,
                            Ku_age: item.Ku_age,
                            Ku_ID: item.Ku_ID,
                            Ku_address: item.Ku_address,
                            Ku_MP: item.Ku_MP,
                            Ku_other: item.Ku_other,
                            K1_code: '',
                            K1_name: '',
                            K1_age: '',
                            K1_ID: '',
                            K1_address: '',
                            K1_MP: '',
                            K1_other: '',
                            K2_code: '',
                            K2_name: '',
                            K2_age: '',
                            K2_ID: '',
                            K2_address: '',
                            K2_MP: '',
                            K2_other: '',
                            Follow_rate: '',
                            Letter_rate: '0',
                            Notice_rate: '0',
                            Ect1_detail: '',
                            Ect1_rate: '1',
                            Ect2_detail: 'นายชำนาญ วังตาล',
                            Ect2_rate: '29',
                            Ect3_detail: 'นางสาว อรอุมา รักศรีอักษร',
                            Ect3_rate: '24',
                            company_name: 'บริษัท เอ็มเอสซีดับเบิลยู  จำกัด',
                            address1: '102 ถนนริมคลองบางกะปิ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310',
                            address2: item.address2,
                            address3: item.address3,
                            Tel: '02-0968782',
                            Fax: 'สำนักงานใหญ่',
                            tax_no: '0115561024484',
                            vat_rate: '',
                            image1: item.image1,
                            image2: '',
                            image3: '',
                            asset: '',
                            asset_show: 'FALSE',
                            dt_im1: '',
                            dt_im2: 'N/A',
                            dt_im3: '',
                            dt_im4: 'ปกติ',
                            birthday: '',
                            max_limit: '0',
                            creditcard: '',
                            bank: '',
                            car_book: '',
                            Interes_cal: '2',
                            Int_daily: '0.0684932',
                            Fee_daily: '',
                            Int_periodly: '',
                            Fee_periodly: '',
                            last_pay: '2/1/1900',
                            last_Int_cal: '',
                            Ref_no: item.Ref_no,
                            First_pay_date: item.First_pay_date,
                            last_cal_after_pay: item.last_cal_after_pay,
                            TAX_Type: '',
                            PlubAsTable: 'FALSE',
                            KU_Fname: '',
                            KU_Lname: '',
                            SystemDate: '',
                            TodayBalance: item.TodayBalance,
                            ProdName: item.ProdName,
                            ProdGroup: 'Installment',
                            LastAmount: '',
                            DueOn: '30',
                            LoanClass: item.LoanClass,
                            PlaceAsset: '2) ไม่มีหลักประกัน',
                            MaxAllow: '50000000',
                            PPP2: item.PPP2,
                            K3_code: '',
                            K3_name: '',
                            K3_ID: '',
                            K3_age: '',
                            K3_address: '',
                            K3_MP: '',
                            K3_other: '',
                            image4: '',
                            FirstCutoffDate: item.FirstCutoffDate,
                            CumPrinciple: '',
                            CumInterest: '',
                            BranchCode: '03',
                            LoanType: item.LoanType,
                            FineRate: '0',
                            DueOn2: '0',
                            LoanObjective: '(ไม่ได้ระบุ)',
                            NCutOff: '1',
                            DueOffsetFromCutoff: '',
                            AssetCode: '',
                            AssetType: '',
                            AccBank: '',
                            AccBranch: '',
                            AccType: '',
                            AccNo: '',
                            AccName: '',
                            AccAssetCode: '',
                            Acc2AssetCode: '',
                            Acc2Bank: '',
                            Acc2Branch: '',
                            Acc2Type: '',
                            Acc2No: '',
                            Acc2Name: '',
                            CarType: '',
                            CarBrand: '',
                            CarNo: '',
                            CarBodyNo: '',
                            CarEngineNo: '',
                            CarValue: '',
                            Car2Type: '',
                            Car2Brand: '',
                            Car2No: '',
                            Car2BodyNo: '',
                            Car2EngineNo: '',
                            Car2Value: '',
                            CarAssetCode: '',
                            Car2AssetCode: '',
                            BizPolicyNo: '',
                            BizPolicyDate: '',
                            BizTotalValue: '',
                            AccMinValueKeep: '',
                            TransferBankName: item.TransferBankName,
                            TransferAccountName: item.TransferAccountName,
                            TransferAccountType: 'ออมทรัพย์',
                            TransferAccountNo: item.TransferAccountNo,
                            KuGroup: '',
                            PromPayIDNO: '',
                            PromPayMBNO: '',
                            BankCode: '',
                            BranchAddress: '102 ถนนริมคลองบางกะปิ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310',
                            BranchTel: 'โทร 0652368245',
                            MinMethod: '',
                            PPNO: '',
                            TotalIncome: item.TotalIncome,
                            IncomeLoanRatio: item.IncomeLoanRatio,
                            AssetValue: '',
                            RentNO: '',
                            RentDate: '',
                            RentOwner: '',
                            Renter: '',
                            RentRate: '',
                            RentDetail: '',
                            RentValue: '',
                            RentAssetCode: '',
                            OtherAssetCode: '',
                            OtherAssetDetail: '',
                            OtherValue: '',
                            OtherValueSay: '',
                            ContractName: item.ContractName,
                            ContractRelation: item.ContactRelation,
                            ContractPhone: item.ContractPhone,
                            SuffixTax: '00',
                            BarcodeText: '',
                            Ku_DateOfBirth: '',
                            RestructureDate: '',
                            BranchProvince: 'กรุงเทพฯ',
                            Lawer: '',
                            CutoffCode1: '',
                            CutoffCode2: '',
                            CutoffTime1: '',
                            CutoffTime2: '',
                            Father: '',
                            Mother: '',
                            SubjectCN: '',
                            Rawang: '',
                            LandNO: '',
                            ChanodeNO: '',
                            LandTB: '',
                            LandDT: '',
                            LandPV: '',
                            SurveyNO: '',
                            ChamnongNO: '',
                            TempField1: '',
                            TempField2: '',
                            ABD_Date: '',
                            TempField3: '',
                            TempField4: '',
                            CloseEarlyPeriod: '',
                            CloseEarlyFine: '',
                            TempField5: '',
                            LastestFollowup: '',
                            LastestAppointment: '',
                            Follower: '',
                            CollectorName: '',
                            SecretNumber: '',
                            Marketer: '',
                            Yokma: '0',
                            LateIntRate: '0',
                            LateIntAfter: '99',
                            WFtie: '',
                            PPPvary: 'FALSE',
                            NCBsend: 'FALSE',
                            CoopIssueDate: '',
                            AgentCode: 'FALSE',
                            CoopChqReceived: '',
                            InteresCal: 'Effective rate',
                            EmploymentStatus: '0787300015',
                            DateOfBirth: '',
                            BusinessType: '0787500010',
                            Occupation: '0787400112',
                            OccEct: 'พนักงานทั่วไป',
                            PolicyGroup: item.PolicyGroup,
                            RePaymentChanal: '',
                            RePaymentType: '',
                            Fee2_Period: '',
                            MileUse: '',
                            LastPaymentAmount: '',
                            PDG: '',
                        })
                    });

                    setExcelData(list_datas);
                    ExcelExport(list_datas, "Policy");
                })
        } else {
            alert('กรุณาเลือกวันที่')
        }
        // console.log(excel_data)
        // ExcelExport(excel_data);
    }

    const handleSearchButton = () => {
        loadAwaitList();
    }
    const handleExportExcelButton_2 = () => {
        let data = {
            transfer_date: from_date
        }
        if (from_date !== null) {
            DirectCredit.GetExcelData2(data).then((res) => {
                const data = res.data.data;
                const list_data = [];
                data.map(item => {
                    list_data.push({
                        CustCode: item.CustCode,
                        CustGroup: item.CustGroup,
                        T_PreName: item.T_PreName,
                        T_Name: item.T_Name,
                        E_PreName: item.E_PreName,
                        E_Name: item.E_Name,
                        E_Digital: item.E_Digital,
                        BirthDate: item.BirthDate,
                        MonthBirth: item.MonthBirth,
                        AGE: item.AGE,
                        IDCNO: item.IDCNO,
                        PassportNO: item.PassportNO,
                        Nation: item.Nation,
                        C_Addr1: item.C_Addr1,
                        C_Addr2: item.C_Addr2,
                        C_TB: item.C_TB,
                        C_DT: item.C_DT,
                        C_PV: item.C_PV,
                        C_ZIP: item.C_ZIP,
                        C_FullAddress: item.C_FullAddress,
                        C_HP: item.C_HP,
                        C_MP: item.C_MP,
                        C_Email: item.C_Email,
                        B_Addr1: item.B_Addr1,
                        B_Addr2: item.B_Addr2,
                        B_TB: item.B_TB,
                        B_DT: item.B_DT,
                        B_PV: item.B_PV,
                        B_ZIP: item.B_ZIP,
                        B_FullAddress: item.B_FullAddress,
                        B_HP: item.B_HP,
                        ResidentType: item.ResidentType,
                        ResidentStatus: item.ResidentStatus,
                        StayLong: item.StayLong,
                        Education: item.Education,
                        MaritalStatus: item.MaritalStatus,
                        SP_Name: item.SP_Name,
                        SP_OCC: item.SP_OCC,
                        SP_Income: item.SP_Income,
                        SP_HP: item.SP_HP,
                        SP_MP: item.SP_MP,
                        ContactPeople: item.ContactPeople,
                        ContactRelation: item.ContactRelation,
                        Contact_HP: item.Contact_HP,
                        Contact_OP: item.Contact_OP,
                        Contact_MP: item.Contact_MP,
                        CompanyType: "ลูกจ้างประจำของเอกชน",
                        BusinessType: "อื่นๆ",
                        OCC: "พนักงานทั่วไป",
                        CompanyName: item.CompanyName,
                        Comp_Address1: item.Comp_Address1,
                        Comp_Address2: item.Comp_Address2,
                        Comp_TB: item.Comp_TB,
                        Comp_DT: item.Comp_DT,
                        Comp_PV: item.Comp_PV,
                        Comp_ZIP: item.Comp_ZIP,
                        Comp_FullAddress: item.Comp_FullAddress,
                        COMP_HP: item.COMP_HP,
                        COMP_MP: item.COMP_MP,
                        Comp_Email: item.Comp_Email,
                        Position: item.Position,
                        JobYear: item.JobYear,
                        JobIncome: item.JobIncome,
                        SpecialIncome: item.SpecialIncome,
                        SpecialIncomeSource: item.SpecialIncomeSource,
                        DocChanal: '',
                        StatementChanal: item.StatementChanal,
                        SelectPayDate: item.SelectPayDate,
                        NewsSMS: '',
                        NewsEmail: '',
                        NewsPost: '',
                        BankName: item.BankName,
                        AccountName: item.AccountName,
                        AccountNO: item.AccountNO,
                        AccountType: item.AccountType,
                        KnownChanal: item.KnownChanal,
                        User1: '',
                        User2: '',
                        User3: '',
                        User4: '',
                        User5: '',
                        User6: '',
                        User7: '',
                        User8: '',
                        User9: '',
                        NOTE: '',
                        Image1: '',
                        Image2: '',
                        Image3: '',
                        ExcelLink: '',
                        T_Sname: item.T_Sname,
                        E_Sname: item.E_Sname,
                        PromPayIDNO: item.PromPayIDNO,
                        PromPayMBNO: item.PromPayMBNO,
                        ReceiveCashAt: item.ReceiveCashAt,
                        BankCode: item.BankCode,
                        TotalIncome: item.TotalIncome,
                        LoanNCB: item.LoanNCB,
                        LoanOther: item.LoanOther,
                        TotalLoan: item.TotalLoan,
                        CreditLimit: item.CreditLimit,
                        Sent2MAC5: item.Sent2MAC5,
                        LineToken: '',
                        LineName: '',
                        OCCGroup: 'พนักงานทั่วไป',
                        NSon: '',
                        LineID: '',
                        ConvenionRepayment: '',
                        MapLink: '',
                        MapLink2: '',
                        MapLink3: '',
                        CreateDate: '',
                        CreateBy: '',
                    })
                })
                setExcelData2(list_data);
                ExcelExport(list_data, "Customer")
            }
            )
        } else {
            alert('กรุณาเลือกวันที่')
        }
        // ExcelExport(excel_data)
    }

    const handleExportPdfButton = () => {
        const selectedData = selected_row.map((selectedIndex) =>
            rows_tran.find((row) => row.id === selectedIndex)
        )
        if (!from_date) {
            alert("โปรดเลือกวันโอนก่อนครับ")
            return
        }
        const pdfDocument = generatePdfDocument(selectedData, from_date)

        //pdfDocument.save()

    }
    const handleClearButton = () => {
        setEndDate(null);
        setFromDate(null);
    }



    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1

        },
        {
            field: 'transfer_date',
            headerName: 'วันที่โอน',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {format(new Date(params.value), 'dd/MM/yyyy')}
                </div>
            )
        },
        {
            field: 'fullname',
            headerName: 'รายชื่อ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'referenceNo',
            headerName: 'referenceNo',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.value === null ? '-' :
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {params.value}
                    </div>
            )
        },
        {
            field: 'transferReferenceNo',
            headerName: 'slip No',
            width: 400,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.value === null ? '-' :
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {params.value}
                    </div>
            )
        },
        {
            field: 'net_transfer',
            headerName: 'ยอดโอนสุทธิ (บาท)',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'bank_name',
            headerName: 'ธนาคาร',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'account_no',
            headerName: 'เลขบัญชี',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'policygroup',
            headerName: 'contact id',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },

        {
            field: 'offer_limit',
            headerName: 'ยอดอนุมัติ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'duty_fee',
            headerName: 'ค่าอากร',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'bureau',
            headerName: 'ค่าตรวจบูโร',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'fee',
            headerName: 'ค่าธรรมเนียม',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            )
        },
        {
            field: 'advancepaid_month1',
            headerName: 'ค่างวดล่วงหน้าเดือน 1',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'advancepaid_month2',
            headerName: 'ค่างวดล่วงหน้าเดือน 2',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'close_payment',
            headerName: 'ยอดชำระเพื่อปิดบัญชีเดิม',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {params.value !== 'null' ? params.value : 0}
                </div>
            ),
        },
        {
            field: 'waive_desc',
            headerName : 'เว้นค่าธรรมเนียม',
            width: 175,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'case_maker',
            headerName: 'รายชื่อตัวแทน',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'direct_debit',
            headerName: 'เข้าร่วม Direct Debit',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'old_policy_group',
            headerName: 'กลุ่มสัญญาเดิม',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        }



    ]
    return (
        <>
            {has_permis && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Success Transfer List</Typography>
                        </Grid>
                    </Grid>
                    <Paper elevation={3}>
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่โอน"
                                        inputFormat="YYYY-MM-DD"
                                        value={from_date}
                                        onChange={(date) => setFromDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item>
                                <Button variant='contained' size='small'
                                    onClick={() => handleSearchButton()}
                                >
                                    ค้นหา
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" size='small'
                                    onClick={() => handleClearButton()}
                                >
                                    เคลียร์
                                </Button>
                            </Grid>
                            <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
                                <Grid item>
                                    <Button variant="contained" size='small'
                                        onClick={() => handleExportPdfButton()}
                                    >
                                        Export to PDF
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained" size='small'
                                        onClick={() => handleExportExcelButton()}
                                    >
                                        Export ไฟล์สร้างสัญญา
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained' size='small'
                                        onClick={() => handleExportExcelButton_2()}
                                    >
                                        Export ไฟล์สร้างลูกค้า
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {open && ( // Render loading indicator when open is true
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                            }}
                        >
                            <DataGrid
                                rows={rows_tran}
                                columns={columns}
                                checkboxSelection
                                disableRowSelectionOnClick
                                selectionModel={selected_row}
                                onSelectionModelChange={handleSelectedChange}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </div>
            )}
        </>
    )
}