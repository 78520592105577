/* eslint-disable no-unused-vars */

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectDebit from '../../services/direct_debit.js';
// components
import MyComponent from '../../components/filter.js';
import { Co2Sharp } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';

export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_tran, setRowsTran] = React.useState(true);
    const location = useLocation();
    const has_permis = checkPermission(location.pathname,decoded);
    React.useEffect(() => {
        if(!has_permis){
            window.location.href = '/404';
        }
        DirectDebit.getTransactionStatus(1,token)
            .then((res) => {
                console.log(res)
                setRowsTran(res.data.data)

            })


    },[])
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        },
        {
            field: 'transaction_date',
            headerName: 'วันที่ชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : params.row.transaction_date.split("T")[0]}`,
        },
        {
            field: 'col3',
            headerName: 'เวลาที่ชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" :  new Date(params.row.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })}`,
        },
        {
            field: 'name',
            headerName: 'ชื่อผู้ชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                 `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'referenceNo',
            headerName: 'รหัสอ้างอิง1',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: 'จำนวนเงิน',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => `${params.row.amount - params.row.vat }`,
        },
        // {
        //     field: 'col7',
        //     headerName: 'วันที่ชำระล่าสุด',
        //     width: 200,
        //     editable: true,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: '',
        //     headerName: 'Action',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderHeader: (params) => (
        //         <MdOpenInNew onClick={() => handleOpen()} fontSize={20} />
        //     ),
        // },

        // {
        // field: '',
        // headerName: '',
        // description: 'This column has a value getter and is not sortable.',
        // sortable: false,
        // type: 'number',
        // width: 160,
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    // rows
    const rows = [
        { id: 1, col2: '2023-05-12', col3: '15:30', col4: 'นางสาวณัฐพร เข็มโรจน์', col5: 'A0001', col6: '1000' },
        { id: 2, col2: '2023-05-12', col3: '16:00', col4: 'นางสาวอรนุช จันทธธรรมโม', col5: 'A0002', col6: '1000' },
        { id: 3, col2: '2023-02-28', col3: '17:30', col4: 'นายโสภณ จิวะกุล', col5: 'A00003', col6: '1000' },
        { id: 4, col2: '2023-02-28', col3: '19:00', col4: 'นางสาวฐิตารีย์ จงมูม', col5: 'A0004', col6: '1000' },
    ];

    // TODO CARE DATA

    return (
        <>
        {has_permis && (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Pay Success Page</Typography>
                </Grid>
            </Grid>
            {
                false ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }

            <Paper elevation={3} >
                <Typography variant='h6' style={{ padding: '10px' }}>Success payment list</Typography>
                <MyComponent />
                <Box
                    sx={{
                        height: 630,
                        width: '100%',
                        // bgcolor:'black'
                    }}
                >
                    <DataGrid
                        rows={rows_tran}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}

                    />
                </Box>
            </Paper>
        </div>
    )}
    </>
    )
}