import * as React from 'react';
import TableData from '../../components/table/document_tracking.js';
import { useEffect } from 'react';
import '../sum.css'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
export default function App() {
    const location = useLocation();
    
    let token = localStorage.getItem('token')
    let decoded
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    var date =  new Date()
    var  today =   ("00" + date.getDate()).slice(-2) + "-"+ ("00" + (date.getMonth() + 1)).slice(-2) + "-" +  date.getFullYear()
    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const has_permission = checkPermission(location.pathname,decoded);

    useEffect(() => {
        if(!has_permission){
            window.location.href = '/404'
        }
    }, [])

    


    return (
        <>
        { has_permission && (
        <div className='document_tracking' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายการติดตามเอกสารวันที่ : {today}</Typography>
                </Grid>
            </Grid>
            <TableData/>
        </div>
        )}
        </>
    )
}