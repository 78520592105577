/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// components
// import MyComponent from '/Users/sarawut/bof-digital-app/src/components/filter.js';

import * as DirectDebit from '../../services/direct_debit.js';
import { checkPermission } from '../../utils/permission_utils.js';
export default function App() {
    const [model, setModel] = useState(false);
    const handleOpenModel = () => setModel(true)
    const handleCloseModel = () => setModel(false);
    const location = useLocation();

    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }
    const { state } = useLocation();
    const [data, setData] = useState()
    const [data_table, setDataTable] = useState([])
    const [user_id, setUserID] = useState(state.user_id)
    const [contract_id, setContractID] = useState(state.contract_id)

    const [start_pay_date, setStartPay] = useState('')
    const [every_pay_date, setEverPay] = useState('')
    const [estimate_installment_amount, setInstallmentAmount] = useState('')
    const [installment_offered, setInstallmentOffered] = useState('')
    const [offer_limit, setOfferLimit] = useState('')
    const [contract_no, setContractNo] = useState('')
    const [openDialog, setOpenDialog] = useState(false);
    const [pin, setPin] = useState('');
    const [action_type, setActionType] = useState('');
    
    const dates = Array.from({ length: 31 }, (_, index) => index + 1);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 950,
        // height: 300,
        bgcolor: 'background.paper',
        // border: '1px solid #000',
        boxShadow: 2,
        p: 4,
    };
    const getUserData = () => {
        let obj = {
            contract_id: contract_id,
            user_id: user_id
        }
        DirectDebit.getUserDetail(obj)
            .then((res) => {
                setDataTable(res.data.data.transaction)
                setData(res.data.data)
                setStartPay(res.data.data.user_detail[0].start_pay_date)
                setEverPay(res.data.data.user_detail[0].every_pay_date)
                setInstallmentAmount(res.data.data.user_detail[0].estimate_installment_amount)
                setInstallmentOffered(res.data.data.user_detail[0].installment_offered)
                setOfferLimit(res.data.data.user_detail[0].offer_limit)
                setContractNo(res.data.data?.user_detail[0]?.contract_no)
            })
    }
    useEffect(() => {
        getUserData()
    }, [])

    const handleCloseDD = (e) => {
        setActionType(2)
        confirmAlert({
            title: 'ยืนยันที่จะปิดสัญญา?',
            message: 'คุณแน่ใจหรือที่จะปิดสัญญา',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        setOpenDialog(true);
                        setPin('');
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("cancel")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }

    const handlePinSubmit = () => {
        if(action_type===1){
            changeCutoffDate()
        }else if(action_type===2){
            closeDDContract()
        }
    };
    const changeCutoffDate = () => {
        let obj = {
            contract_id: contract_id,
            user_id: user_id,
            user_action:decoded.user_id,
            pin:pin
        }
        DirectDebit.changeCutoffDate(obj,token)
            .then((res) => {
                if (res.data.success) {
                    alert("บันทึกสำเร็จ")
                    setOpenDialog(false);
                    getUserData()
                } else if(res.data.code==='01'){
                    alert("PINไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง")
                }else{
                    alert("บันทึกไม่สำเร็จ")
                }
            })
    }
    const closeDDContract = () => {
        let obj = {
            contract_id: contract_id,
            user_id: user_id,
            user_action:decoded.user_id,
            pin:pin
        }
    
        DirectDebit.closeDDContract(obj,token)
            .then((res) => {
                if (res.data.success) {
                    alert("บันทึกสำเร็จ")
                    getUserData()
                    setOpenDialog(false)
                } else if(res.data.code==='01'){
                    alert("PINไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง")
                }else{
                    alert("บันทึกไม่สำเร็จ")
                }
            })
    }
    const handleCutoffDate = (e) => {
        setActionType(1)
        const moment = require("moment-timezone")
        moment.tz.setDefault("Asia/Bangkok")
        var duration = moment.duration({ 'months': 1 })
        let check_month = moment(new Date()).add(duration).format('YYYY-MM') + '-25'
        confirmAlert({
            title: 'ยืนยันที่จะหยุดการตัดเงินอัตโนมัติในรอบนี้ รอบตัดเงินถัดไป ' + check_month,
            message: 'รอบการตัดเงิน รอบต่อไปวันที่ ' + check_month,
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        setOpenDialog(true);
                        setPin('');
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("cancel")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }
    const handleSubmit = () => {
        let object = {
            user_id: user_id,
            contract_id: contract_id,
            start_pay_date: start_pay_date,
            every_pay_date: every_pay_date,
            estimate_installment_amount: estimate_installment_amount,
            installment_offered: installment_offered,
            offer_limit: offer_limit,
            contract_no: contract_no

        }
        DirectDebit.editUserDetail(object,token)
            .then((res) => {
                if (res.data.success) {
                    alert('บันทึกสำเร็จ')
                    handleCloseModel()
                    window.location.reload();

                } else {
                    alert('บันทึกไม่สำเร็จ')
                }
            })
    }

    // convert financial value
    const financial = (n) => {
        if (n == 0) {
            return "0"
        } else if (n === null || n === undefined || isNaN(n)) {
            return "0"
        } else {
            return parseFloat(n).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    }

    // clean text value
    const cleanText = (text) => {
        if (text === null || text === undefined || text === "") {
            return "-"
        } else {
            return text.replace("null", "")
        }
    }

    // clean date value
    const cleanDate = (date) => {
        if (date === null || date === undefined || date === "") {
            return "-"
        } else {
            return date.split(' ')[0]
        }
    }

    // calculate age
    const calculateAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }


    // backdrop
    const [open, setOpen] = useState(true);

    // columns
    const columns = [
        {
            field: 'transaction_id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        },
        {
            field: 'transaction_date',
            headerName: 'Transaction date',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>

                `${params.row.transaction_date == null ? "-" : params.row.transaction_date.split("T")[0]}`,
        },
        {
            field: 'amount',
            headerName: 'จำนวนเงิน',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'transaction_status',
            headerName: 'Transaction status',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_status == 1 ? "สำเร็จ" : 'ไม่สำเร็จ'}`
        },
    ];

    // rows

    // TODO CARE DATA
    return (

        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Customer Details</Typography>
                </Grid>
                <Grid item style={{ marginRight: '2rem' }}>
                    <Button variant="outlined" color="error" onClick={() => handleCutoffDate()}>เปลี่ยนรอบเรียกเก็บเงิน</Button>
                </Grid>
                <Grid item style={{ marginRight: '2rem' }}>
                    <Button variant="outlined" color="error" onClick={() => handleCloseDD()}>ปิดสัญญา <br />(ยกเลิก Consent)</Button>
                </Grid>
                <Grid item style={{ marginRight: '2rem' }}>
                    <Button variant="outlined" onClick={() => handleOpenModel()}>แก้ไขรายละเอียดผู้ใช้</Button>
                </Grid>

                {/* let start_pay_date = req.body.start_pay_date
        let every_pay_date = req.body.every_pay_date
        let repeat_cycle = req.body.repeat_cycle */}
                {/* <Grid item>
                    <Button variant="outlined" color="error" style={{ width: '100px' }} onClick={() => handlePayment()}>
                        สั่งตัดเงิน
                    </Button>
                </Grid> */}
            </Grid>

            <Paper elevation={3} style={{ marginBottom: '30px' }}>
                <div style={{ padding: '10px' }}>
                    <Typography variant='h6' style={{ marginBottom: '20px' }}>Customer Details</Typography>
                    {/* <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>ข้อมูลทั่วไป</Typography>
                        </AccordionSummary>
                        <AccordionDetails> */}
                    <Grid container spacing={2}>
                        <Grid item xs={1.5}><TextField id="outlined-basic" label="รหัสลูกค้า" variant="outlined" value={cleanText(data?.user_detail[0]?.user_id)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="วันเกิด" variant="outlined" value={cleanDate(data?.user_detail[0]?.birth_date.split("T")[0])} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="ชื่อ" variant="outlined" value={cleanText(data?.user_detail[0]?.name)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="นามสกุล" variant="outlined" value={cleanText(data?.user_detail[0]?.lastname)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2.5}><TextField id="outlined-basic" label="เลขบัตรประชาชน" variant="outlined" value={cleanText(data?.user_detail[0]?.id_card)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="โทรศัพท์มือถือ" variant="outlined" value={cleanText(data?.user_detail[0]?.phone)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="เลขที่สัญญา" variant="outlined" value={cleanText(data?.user_detail[0]?.contract_no)} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="วงเงินกู้" variant="outlined" value={data?.user_detail[0]?.offer_limit} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="จำนวนงวด (งวดเดือน)" variant="outlined" value={data?.user_detail[0]?.installment_offered} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="จำนวนงวดคงเหลือ" variant="outlined" value={data?.user_detail[0]?.remaining_installments} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="ค่างวด(บาท)" variant="outlined" value={data?.user_detail[0]?.estimate_installment_amount} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="วันที่เก็บเงินรอบถัดไป" variant="outlined" value={data?.user_detail[0]?.check_date === null ? "-" : data?.user_detail[0]?.check_date.split("T")[0]} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="สถานะบัญชี" variant="outlined" value={data?.user_detail[0]?.status_acc === 'close' ? 'CLOSE' : 'ACTIVE'} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                        <Grid item xs={2}><TextField id="outlined-basic" label="วันที่ปิดบัญชี" variant="outlined" value={data?.user_detail[0]?.status_acc === 'close' ? data?.user_detail[0]?.update_date.split("T")[0] : '-'} InputLabelProps={{ shrink: true, }} InputProps={{ readOnly: true }} fullWidth /></Grid>
                    </Grid>
                    {/* </AccordionDetails>
                    </Accordion> */}

                </div>
            </Paper>

            {
                false ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }

            <Paper elevation={3} >
                <Typography variant='h6' style={{ padding: '10px' }}>Customer payment history</Typography>
                <Box
                    sx={{
                        height: 630,
                        width: '100%',
                        // bgcolor:'black'
                    }}
                >
                    <DataGrid
                        rows={data_table}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}

                    />
                </Box>
            </Paper>

            <Modal
                open={model}
                onClose={handleCloseModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 900 }}
            >

                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Grid item xs><TextField id="outlined-basic" label="เลขที่สัญญา" variant="outlined" type="text" value={contract_no} onChange={(e) => setContractNo(e.target.value)} /></Grid>
                        </Grid>
                        <Grid item>
                            <Grid item xs><TextField id="outlined-basic" label="วงเงินกู้" variant="outlined" type="number" value={offer_limit} onChange={(e) => setOfferLimit(parseInt(e.target.value))} /></Grid>
                        </Grid>

                        <Grid item>
                            <Grid item xs><TextField id="outlined-basic" label="จำนวนงวด" variant="outlined" type="number" value={installment_offered} onChange={(e) => setInstallmentOffered(parseInt(e.target.value))} /></Grid>
                        </Grid>
                        <Grid item>
                            <Grid item xs><TextField id="outlined-basic" label="ค่างวด(บาท)" variant="outlined" type="number" value={estimate_installment_amount} onChange={(e) => setInstallmentAmount(parseInt(e.target.value))} /></Grid>
                        </Grid>
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="วันที่ตัดเงินครั้งแรก"
                                    inputFormat="YYYY-MM-DD"
                                    value={start_pay_date}
                                    onChange={(e) => setStartPay(e)}
                                    renderInput={(params) => <TextField size="normal" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item>
                            <Box sx={{ minWidth: 200 }}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">ตัดเงินทุกๆวันที่</InputLabel>
                                    <Select
                                        labelId="simple-select"
                                        id="simple-select"
                                        value={every_pay_date}
                                        label="ตัดเงินทุกๆวันที่"
                                        onChange={(e) => setEverPay(e.target.value)}
                                    >
                                        {dates.map(date => (
                                            <MenuItem key={date} value={date}>
                                                {date}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" color="success" style={{ width: '150px' }} onClick={() => handleSubmit()}>ยืนยัน</Button>
                            <Button variant="outlined" color="error" style={{ width: '150px', marginLeft: '10px' }} onClick={() => handleCloseModel()}>ยกเลิก</Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
            {/* PIN Entry Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Enter 4-Digit PIN</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your 4-digit PIN to proceed.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="PIN"
                        type="password"
                        fullWidth
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        inputProps={{
                            maxLength: 4,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        ยกเลิก
                    </Button>
                    <Button onClick={handlePinSubmit} color="primary">
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>
            {/* PIN Tranfer Dialog */}
        </div>

    )
}