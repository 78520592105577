import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';

import * as ncbHttpServices from '../../services/ncb_services';


export default function FormDialog({ open, handleClose, handleOpen, contact_id, table }) {
    const getToken = () => {
        let token = localStorage.getItem('token_org')
        return token
    }

    const [extra, setExtra] = useState('ไม่มี');
    const [obj, setObj] = useState();
    const [fileInputValue, setFileInputValue] = useState('');
    const fileInput = useRef(null);
    const contactId = contact_id

    function handleFileChange(e) {
        setFileInputValue(e.target.value);

        let file = e.target.files[0]
        let reader = new FileReader();

        reader.readAsText(file);
        reader.onload = () => {
            readerFile(reader.result, file.name, contactId)
        }
        reader.onerror = () => {
            console.log('file error', reader.error)
        }
    }

    function readerFile(fileContent, name, contactId) {
        var jsonDataArray_PN = [];
        var jsonDataArray_ID = [];
        var jsonDataArray_PA = [];
        var jsonDataArray_IQ = [];
        var jsonDataArray_TL = [];
        var jsonDataArray_HS = [];
        var err = false

        //TUEF Response Header
        let enquiryDate
        let today = new Date()

        if (isNaN(new Date(fileContent.substring(6, 14).replace(/(\d{2})(\d{2})(\d{4})/g, '$3-$2-$1')))) {
            enquiryDate = today.toISOString().slice(0, 10)
        } else {
            enquiryDate = fileContent.substring(6, 14).replace(/(\d{2})(\d{2})(\d{4})/g, '$3-$2-$1')
        }

        const jsonDataHeader = {
            segment: fileContent.substring(0, 4),
            version: fileContent.substring(4, 6),
            memberReferenceNumber: fileContent.substring(6, 31),
            country: fileContent.substring(31, 33),
            memberCode: fileContent.substring(33, 43),
            subjectReturnCode: fileContent.substring(43, 44),
            enquirtControlNumber: fileContent.substring(44, 53),
            enquityDate: enquiryDate,
        }

        if (jsonDataHeader.segment === 'TUEF') {
            //PN Name
            let PN = (fileContent.slice(fileContent.search('PN03'), fileContent.search('ID03')))

            for (let j = 0; j < 2; j++) {
                var PN_Array = []
                for (let i = 0; i < 15; i++) {
                    if (parseInt((PN.charAt(0) + PN.charAt(1))) === i || (PN.charAt(0) + PN.charAt(1)) === 'PN') {
                        PN_Array[i] = PN.slice(4, parseInt(PN.charAt(2) + PN.charAt(3)) + 4)
                        PN = (PN.slice(parseInt(PN.charAt(2) + PN.charAt(3)) + 4))
                        // console.log(PN_Array[i], i)
                    } else {
                        PN_Array[i] = undefined
                    }
                }

                if (PN_Array[7] !== undefined) PN_Array[7] = PN_Array[7].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

                const jsonData = {
                    segment: PN_Array[0],
                    familyName1: PN_Array[1],
                    familyName2: PN_Array[2],
                    firstName: PN_Array[4],
                    middle: PN_Array[5],
                    maritalStatus: PN_Array[6],
                    dateOfBirth: PN_Array[7],
                    gender: PN_Array[8],
                    title: PN_Array[9],
                    nationality: PN_Array[10],
                    numberOfChilden: PN_Array[11],
                    spouseName: PN_Array[12],
                    occupation: PN_Array[13],
                    consentToEnquire: PN_Array[14]
                }
                let key = PN_Array[0]
                jsonDataArray_PN[key] = jsonData

                // console.log(jsonDataArray_PN)
                if (PN.length === 0) break
            }

            //ID Identification Number
            let ID
            if (fileContent.search('PA03') > 0) {
                ID = (fileContent.slice(fileContent.search('ID03'), fileContent.search('PA03')))
            } else {
                ID = ''
            }
            // console.log(ID)

            if (ID !== '') {
                var ID_Array = []
                do {
                    for (let i = 0; i < 4; i++) {
                        if (parseInt((ID.charAt(0) + ID.charAt(1))) === i || ((ID.charAt(0) + ID.charAt(1)) === 'ID' && i === 0)) {
                            ID_Array[i] = ID.slice(4, parseInt(ID.charAt(2) + ID.charAt(3)) + 4)
                            ID = (ID.slice(parseInt(ID.charAt(2) + ID.charAt(3)) + 4))
                        } else {
                            ID_Array[i] = undefined
                        }
                    }

                    const jsonData = {
                        segment: ID_Array[0],
                        IDType: ID_Array[1],
                        IDNumber: ID_Array[2],
                        IDIssueCountry: ID_Array[3],
                    }

                    let key = ID_Array[0]
                    jsonDataArray_ID[key] = jsonData

                    if (jsonDataArray_ID[key].segment === undefined) {
                        err = true
                        break
                    }
                } while (ID !== '' && !err)
            }

            //PA Address
            let PA
            if (fileContent.search('TL04') > 0) {
                PA = (fileContent.slice(fileContent.search('PA03'), fileContent.search('TL04')))
            } else if (fileContent.search('IQ04') > 0) {
                PA = (fileContent.slice(fileContent.search('PA03'), fileContent.search('IQ04')))
            } else if (fileContent.search('SC02') > 0) {
                PA = (fileContent.slice(fileContent.search('PA03'), fileContent.search('SC02')))
            } else {
                PA = ''
            }

            if (PA !== '') {
                var PA_Array = []
                do {
                    for (let i = 0; i < 14; i++) {
                        if (parseInt((PA.charAt(0) + PA.charAt(1))) === i || (i === 0 && PA.charAt(0) + PA.charAt(1)) === 'PA') {
                            PA_Array[i] = PA.slice(4, parseInt(PA.charAt(2) + PA.charAt(3)) + 4)
                            PA = (PA.slice(parseInt(PA.charAt(2) + PA.charAt(3)) + 4))
                        } else {
                            PA_Array[i] = undefined
                        }
                    }

                    const jsonData = {
                        segment: PA_Array[0],
                        address1: PA_Array[1],
                        address2: PA_Array[2],
                        address3: PA_Array[3],
                        subDistrict: PA_Array[4],
                        district: PA_Array[5],
                        province: PA_Array[6],
                        country: PA_Array[7],
                        postalCode: PA_Array[8],
                        telephone: PA_Array[9],
                        telephoneType: PA_Array[10],
                        addressType: PA_Array[11],
                    }

                    let key = PA_Array[0]
                    jsonDataArray_PA[key] = jsonData

                    if (jsonDataArray_PA[key].segment === undefined) {
                        err = true
                        break
                    }
                } while (PA !== '' && !err)
            }

            //TL Account
            let TL
            if (fileContent.search('IQ04') > 0) {
                TL = (fileContent.slice(fileContent.search('TL04'), fileContent.search('IQ04')))
            } else if (fileContent.search('SC02') > 0) {
                TL = (fileContent.slice(fileContent.search('TL04'), fileContent.search('SC02')))
            } else {
                TL = ''
            }

            if (TL !== '') {
                do {
                    var TL_Array = []
                    if ((TL.charAt(0) + TL.charAt(1)) === 'TL') {
                        for (let i = 0; i < 43; i++) {
                            if (parseInt((TL.charAt(0) + TL.charAt(1))) === i || (i === 0 && (TL.charAt(0) + TL.charAt(1)) === 'TL')) {

                                TL_Array[i] = TL.slice(4, parseInt(TL.charAt(2) + TL.charAt(3)) + 4)
                                TL = (TL.slice(parseInt(TL.charAt(2) + TL.charAt(3)) + 4))

                            } else {
                                TL_Array[i] = undefined
                            }

                            if ((TL.charAt(0) + TL.charAt(1)) === 'HS') break
                        }
                    }

                    // if (TL_Array[0] !== undefined) {
                    if (TL_Array[12] === undefined) TL_Array[12] = '0'
                    if (TL_Array[13] === undefined) TL_Array[13] = '0'
                    if (TL_Array[14] === undefined) TL_Array[14] = '0'
                    if (TL_Array[21] === undefined) TL_Array[21] = '0'
                    if (TL_Array[37] === undefined) TL_Array[37] = '0'

                    if (TL_Array[8] !== undefined) TL_Array[8] = TL_Array[8].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (TL_Array[9] !== undefined) TL_Array[9] = TL_Array[9].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (TL_Array[10] !== undefined) TL_Array[10] = TL_Array[10].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (TL_Array[11] !== undefined) TL_Array[11] = TL_Array[11].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (TL_Array[19] !== undefined) TL_Array[19] = TL_Array[19].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (TL_Array[30] !== undefined) TL_Array[30] = TL_Array[30].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (TL_Array[31] !== undefined) TL_Array[31] = TL_Array[31].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

                    const jsonData = {
                        segment: TL_Array[0],
                        memberCode: TL_Array[1],
                        memberShortName: TL_Array[2],
                        accountNumber: TL_Array[3],
                        accountType: TL_Array[4],
                        ownershipIndicator: TL_Array[5],
                        currencyCode: TL_Array[6],
                        dateAccountOpened: TL_Array[8],
                        dateOfLastPayment: TL_Array[9],
                        dateAccountClosed: TL_Array[10],
                        asOfDate: TL_Array[11],
                        creditLimit: TL_Array[12],
                        amountOwed: TL_Array[13],
                        amountPastDue: TL_Array[14],
                        defaultDate: TL_Array[19],
                        installmentFrequency: TL_Array[20],
                        installmentAmount: TL_Array[21],
                        installmentNumberOfPayment: TL_Array[22],
                        accountStatus: TL_Array[23],
                        loanClass: TL_Array[24],
                        paymentHistory1: TL_Array[28],
                        paymentHistory2: TL_Array[29],
                        paymentHistoryStartDate: TL_Array[30],
                        paymentHistoryEndDate: TL_Array[31],
                        loanObjective: TL_Array[32],
                        collateral1: TL_Array[33],
                        collateral2: TL_Array[34],
                        collateral3: TL_Array[35],
                        dateOfLastDebtRestructure: TL_Array[36],
                        percentPayment: TL_Array[37],
                        typeOfCreditCard: TL_Array[38],
                        numberOfCoborrowers: TL_Array[39],
                        unitMake: TL_Array[40],
                        unitModel: TL_Array[41],
                        creditTypeFlag: TL_Array[42],
                    }

                    let key = TL_Array[0]
                    jsonDataArray_TL[key] = jsonData
                    // }

                    if (jsonDataArray_TL[key].segment === undefined) {
                        err = true
                        break
                    }

                    if ((TL.charAt(0) + TL.charAt(1)) === 'HS') {
                        let HS_Array = []

                        for (let j = 0; j < 50; j++) {
                            for (let i = 0; i < 5; i++) {
                                if (parseInt((TL.charAt(0) + TL.charAt(1))) === i || (i === 0 && (TL.charAt(0) + TL.charAt(1)) === 'HS')) {
                                    HS_Array[i] = TL.slice(4, parseInt(TL.charAt(2) + TL.charAt(3)) + 4)
                                    TL = (TL.slice(parseInt(TL.charAt(2) + TL.charAt(3)) + 4))
                                } else {
                                    HS_Array[i] = undefined
                                }
                            }

                            if (HS_Array[1] !== undefined) HS_Array[1] = HS_Array[1].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                            if (HS_Array[4] === undefined) HS_Array[4] = 0

                            const jsonData = {
                                segment: HS_Array[0],
                                asOfDate: HS_Array[1],
                                overDueMonths: HS_Array[2],
                                creditLimit: HS_Array[3],
                                amountOwed: HS_Array[4],
                            }


                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            jsonDataArray_HS = jsonDataArray_HS.concat(jsonData)

                            if ((TL.charAt(0) + TL.charAt(1)) === 'TL' || (TL === '')) break
                        }
                    }
                } while (TL !== '' && !err)
            }

            //IQ Enquity
            let IQ
            if (fileContent.search('DR03') > 0) {
                IQ = (fileContent.slice(fileContent.search('IQ04'), fileContent.search('DR03')))
            } else if (fileContent.search('SC02') > 0) {
                IQ = (fileContent.slice(fileContent.search('IQ04'), fileContent.search('SC02')))
            } else {
                IQ = ''
            }

            if (IQ !== '') {
                do {
                    var IQ_Array = []
                    for (let i = 0; i < 8; i++) {
                        if (parseInt((IQ.charAt(0) + IQ.charAt(1))) === i || (i === 0 && IQ.charAt(0) + IQ.charAt(1) === 'IQ')) {
                            IQ_Array[i] = IQ.slice(4, parseInt(IQ.charAt(2) + IQ.charAt(3)) + 4)
                            IQ = (IQ.slice(parseInt(IQ.charAt(2) + IQ.charAt(3)) + 4))
                        } else {
                            IQ_Array[i] = undefined
                        }
                    }

                    if (IQ_Array[1] !== undefined) IQ_Array[1] = IQ_Array[1].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                    if (IQ_Array[2] !== undefined) IQ_Array[2] = IQ_Array[2].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

                    const jsonData = {
                        segment: IQ_Array[0],
                        dateOfEnquiry: IQ_Array[1],
                        timeOfEnquiry: IQ_Array[2],
                        memberCode: IQ_Array[3],
                        memberShortName: IQ_Array[4],
                        enquiryPurpose: IQ_Array[5],
                        enquityAmount: IQ_Array[6],
                        currencyCode: IQ_Array[7],
                    }

                    let key = IQ_Array[0]
                    jsonDataArray_IQ[key] = jsonData

                    if (jsonDataArray_IQ[key].segment === undefined) {
                        err = true
                        break
                    }
                } while (IQ !== '' && !err)
            }

            //DR

            //SC Score Segment
            let SC = (fileContent.slice(fileContent.search('SC02'), fileContent.search('ES05')))

            var SC_Array = []
            for (let i = 0; i < 20; i++) {
                if (parseInt((SC.charAt(0) + SC.charAt(1))) === i || (SC.charAt(0) + SC.charAt(1)) === 'SC') {
                    SC_Array[i] = SC.slice(4, parseInt(SC.charAt(2) + SC.charAt(3)) + 4)
                    SC = (SC.slice(parseInt(SC.charAt(2) + SC.charAt(3)) + 4, SC.length))
                } else {
                    SC_Array[i] = undefined
                }
            }

            const jsonDataArray_SC = {
                segment: SC_Array[0],
                scoreVersion: SC_Array[1],
                scoreSegment: SC_Array[2],
                recalibrationVersion: SC_Array[3],
                scoreVersionDate: SC_Array[4],
                score: SC_Array[5],
                scoreGrade: SC_Array[6],
                odds: (10000 - SC_Array[7] + ':' + parseInt(SC_Array[7])),
                reasonCode1: SC_Array[13],
                reasonCode2: SC_Array[14],
                reasonCode3: SC_Array[15],
                reasonCode4: SC_Array[16],
                reasonCode5: SC_Array[17],
                errorCode: SC_Array[18],
                enquirtControlNumber: SC_Array[19],
                // status: extra,
            }

            //ES End

            const finalObj = {
                file: name,
                // file_detail: fileContent,
                ncb_id: fileContent.substring(44, 53),
                uploadDate: today,
                header: jsonDataHeader,
                score: jsonDataArray_SC,
                name: Object.assign({}, jsonDataArray_PN),
                id: Object.assign({}, jsonDataArray_ID),
                address: Object.assign({}, jsonDataArray_PA),
                iq: Object.assign({}, jsonDataArray_IQ),
                account: Object.assign({}, jsonDataArray_TL),
                history: Object.assign({}, jsonDataArray_HS),
            }

            console.log('finalObj', finalObj)
            setObj(finalObj)

            ncbHttpServices.checkID(finalObj.ncb_id, getToken())
                .then((res) => {
                    if (res.data.status === false) {
                        alert('ไฟล์ NCB นี้มีการอัปโหลดแล้ว ที่ Contact ID: ' + res.data.data[0]?.contact_id)
                        // setObj()
                        setFileInputValue('');
                        // setOpen(false)
                    }
                    if (err) {
                        alert('ไฟล์ NCB นี้มีปัญหา รบกวนติดต่อกับทางทีมทรานฟินเมชั่น')
                        setFileInputValue('');
                        console.log('err', err)
                        err = false
                    }

                })
        } else {
            alert('กรุณาอัปโหลดไฟล์ NCB ที่ถูกต้อง')
            setFileInputValue('');
        }

    }

    const handleSubmit = () => {
        const data = obj
        data.score['status'] = extra;

        if (table.length === 0) {
            //CREATE
            const body = {
                contact_id: contactId,
                ncb_files: {
                    ncb1: data
                }
            }

            console.log('body', body)

            ncbHttpServices.createUser(body, getToken())
                .then((res) => {
                    alert('อัปโหลดไฟล์ NCB สำเร็จ')
                    // localStorage.setItem('upload', 'ok')
                    window.location.reload(true);
                })
        }
        else if (table.length < 4) {
            //INSERT
            let key = 'ncb' + (table.length + 1)

            const body = {
                contact_id: contactId,
                [key]: data
            }
            console.log('body', body)

            ncbHttpServices.insert(body, getToken())
                .then((res) => {
                    alert('อัปโหลดไฟล์ NCB สำเร็จ')
                    localStorage.setItem('upload', 'ok')
                    window.location.reload(true);
                })
        }
        else {
            //MAXIMUM
            alert('ขณะนี้สามารถอัปโหลดไฟล์ NCB ได้สูงสุดที่ 4 ไฟล์')
            // setOpen(false)
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>อัปโหลดไฟล์ NCB</DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        ไฟล์ NCB
                    </Typography>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        value={fileInputValue}
                        onChange={event => handleFileChange(event, contact_id)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            backgroundColor: "#FFFFFF",
                            mb: 2
                        }}
                        type="file"
                        ref={fileInput}
                        inputProps={{ accept: '.txt' }}
                    />
                    <Typography variant="body1" gutterBottom>
                        ประเภทเสีย NCB พิเศษ
                    </Typography>
                    <Autocomplete
                        value={extra}
                        onChange={(event, newValue) => {
                            setExtra(newValue);
                        }}
                        id="autocomplete-bank"
                        options={['ไม่มี', 'โดนอายัดเงินเดือน', 'บุคคลล้มละลาย']}
                        renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} />}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" onClick={handleSubmit} disabled={!fileInputValue || !Boolean(extra)} sx={{ m: 1 }}>คำนวณ</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
