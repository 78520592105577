import axios from "axios";
import jwt_decode from "jwt-decode";
const config = require('../config.js')
let ROOT_URL = config.PICO_URL
export const getCollectorExport = (token, body) => {
    return axios.post(`${ROOT_URL}/hubspot/contacts/export2`, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}