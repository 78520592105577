exports.ROOT_URL = "https://master-api.pico.tfm.in.th"
exports.PROD_API_URL = "https://apiv2-uat.transfinmational.net"  //collecter api
exports.ORC_URL = "https://ocr-api.pico.tfm.in.th"
exports.FILE_API_URL = "https://fliemng-api.pico.tfm.in.th"
exports.COMMITEE_URL = "https://committee.pico.tfm.in.th"
exports.PICO_URL = "https://ncb-api.pico.tfm.in.th"
exports.LOGIN_URL = "https://auth-api.pico.tfm.in.th"
exports.DD_URL = "https://payment-api.pico.tfm.in.th"
exports.DC_URL = "https://payment-api.pico.tfm.in.th"

// REACT_APP_AUTHENTICATION=false
// ROOT_URL="https://master-api.staging.pico.batmaster.io:40443"
// PROD_API_URL="https://apiv2-uat.staging.pico.batmaster.io:40443", # TODO CHECK
// ORC_URL="https://ocr-api.staging.pico.batmaster.io:40443"
// FILE_API_URL="https://fliemng-api.staging.pico.batmaster.io:40443"
// MSCW_URL="https://ncb-api.staging.pico.batmaster.io:40443"
// PICO_URL="https://api-uat.transfinmational.net" # NOT USE NOW
// LOGIN_URL="https://auth-api.staging.pico.batmaster.io:40443"
// exports.DD_URL="http://localhost:44440"
// exports.DC_URL="http://localhost:44446"
// # DC_URL="https://payment-api.staging.pico.batmaster.io:40443"
