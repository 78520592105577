import axios from "axios";
const config = require('../config.js')

const FILE_API_URL = config.FILE_API_URL;
const ORC_URL = config.ORC_URL;

// Statement
export const getStatements = (contact_id) => {
    return axios.get(`${FILE_API_URL}/get_statements/${contact_id}`)
}

export const delStatementOCR = (statement_id) => {
    return axios.get(`${FILE_API_URL}/del_statement/ocr/${statement_id}`)
}

export const delStatementKeyin = (statement_id) => {
    return axios.get(`${FILE_API_URL}/del_statement/keyin/${statement_id}`)
}

// OCR
export const callOCRStatement = (formData) => {
    console.log('formData callOCRStatement', ...formData)
    return axios.post(
        `${ORC_URL}/api/ocr`, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
};

// OCR DGA
export const callOCRStatement2 = (body) => {
    console.log('body of callOCRStatement2', body)
    return axios.post(`${ORC_URL}/api/ocr/uploaded`, body)
};

export const getOcrStatement = (statement_id) => {
    return axios.get(`${FILE_API_URL}/get_ocr_statement/${statement_id}`)
}

export const editOcrStatement = (body) => {
    return axios.post(`${FILE_API_URL}/edit_ocr_statement`, body);
}

// Keyin
export const saveManualStatement = (body) => {
    return axios.post(`${FILE_API_URL}/save_manual_statement`, body);
}

export const getKeyinStatement = (statement_id) => {
    return axios.get(`${FILE_API_URL}/get_keyin_statement/${statement_id}`)
}

export const editKeyinStatement = (body) => {
    return axios.post(`${FILE_API_URL}/edit_keyin_statement`, body);
}

// Else
export const calculatStatement = (contact_id) => {
    return axios.post(`${FILE_API_URL}/cal_statement`, contact_id)
}

// const DGA_STATEMENTS_ENDPOINT = 'http://localhost:4440/dgaStatements';

export const dgaStatements = async (body) => {
    try {
        console.log(`dgaStatements ${JSON.stringify(body)}`);
        // const response = await axios.post(DGA_STATEMENTS_ENDPOINT, body);
        const response = await axios.post(`${FILE_API_URL}/dgaStatements`, body);
        return response.data;
    } catch (error) {
        console.error('Error in dgaStatements:', error);
        throw error;
    }
}