import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewLogin from './page/login/login'
// import LoginOrg from './page/login/login_org'
import DocumentTracking from './page/CMS/document_tracking'
import DocumentTrackingUser from './page/CMS/document_tracking_user'
import Preview from './page/CMS/preview'
import CustomerPending from './page/CMS/customer_pending'

// new
// import CustomersList from './page/customers_list'
import Dev from './page/dev'

// statement quick
import StatementQuick from './page/statement/statement_tem'

// statement
import StatementCustomersList from './page/statement/customers_list'
import StatementFilesList from './page/statement/files_list'
import StatementAnalysisPreview from './page/statement/analysis_preview'
import StatementAnalysisPreviewCommittee from './page/statement/analysis_preview_committee'
import LoanStatementAnalysisPreview from './page/statement/loan_analysis_preview'
import LoanStatementAnalysisPreviewCommittee from './page/statement/loan_analysis_preview_committee'
import StatementAnalysis from './page/statement/analysis'
import UploadManualPage from './page/statement/UploadManualPage'
import EditStatement from './page/statement/EditStatement'
import StatementFileManagement from './page/statement/file_management'
import EditKeyin from './page/statement/editKeyin'
import EditOcr from './page/statement/editOcr'


// direct-credit
import DCTransferWaiting from './page/direct_credit/transfer_waiting_page'
import DCTransferReady from './page/direct_credit/transfer_ready_page'
import DCTransferSuccess from './page/direct_credit/transfer_success_page'
import DCCreditOper from './page/direct_credit/credit_oper'
import DCCreditOperDetail from './page/direct_credit/credit_oper_detail'
// direct-debit
import DDCustomerList from './page/direct_debit/customer_list'
import DDCustomerDetails from './page/direct_debit/customer_details'
import DDPaySuccessPage from './page/direct_debit/pay_success_page'
import DDPaySuccessExport from './page/direct_debit/pay_success_export'
import DDPayUnsuccessPage from './page/direct_debit/pay_unsuccess_page'
// import DDCustomerListCompare from './page/direct_debit/customer_list_compare'
import DDManualProcessPage from './page/direct_debit/manual_process_page'
import UserStatus from './page/CMS/user'
import NotFound from './page/notfound'
import DDAutoPartial from './page/direct_debit/auto_partial_list'


// NCB
import List from './page/ncb/ncb_list';
import Detail from './page/ncb/ncb_detail'
import Summarize from './page/ncb/ncb_summarize'
import NCBFileManagement from './page/ncb/file_management'
import NCBTable from './page/ncb/ncb_table'

// colecttor
import ContactManagement from './page/collector/contact_management'
import ContactCollector from './page/collector/contact_collector'
import ContactDetail from './page/collector/contact_detail'
import DirectDebitReport from './page/direct_debit/report';
// components

import BankChange from './page/bank_change'
//Marketing
import Marketing from './page/Marketing/marketing';
import MarketingTracking from './page/Marketing/marketing_tracking';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewLogin />} />
        <Route path="/login" element={<NewLogin />} />
        {/* <Route path="/login_org" element={<LoginOrg />} /> */}
        <Route path="/document_tracking" element={<DocumentTracking />} />
        <Route path="/document_tracking_user" element={<DocumentTrackingUser />} />
        <Route path="/customer_pending" element={<CustomerPending />} />
        <Route path="/user_status" element={<UserStatus />} />

        <Route path="/preview" element={<Preview />} />

        {/* components */}
        {/* <Route path="/filter" element={<Filter />} /> */}

        {/* NCB */}
        <Route path="/ncb/file_management" element={<NCBFileManagement />} />
        <Route path="/ncb/list/:contact_id" element={<List />} />
        <Route path="/ncb/:detail/:contact_id" element={<Detail />} />
        <Route path="/ncb/summarize/:contact_id" element={<Summarize />} />
        {/* <Route path="/customers_list" element={<CustomersList />} /> */}
        {/* <Route path="/statement_analysis" element={<Statement />} /> */}

        {/* statement */}
        <Route path="/statement/customers_list" element={<StatementCustomersList />} />
        <Route path="/statement/files_list/:contact_id" element={<StatementFilesList />} />
        <Route path="/statement/analysis_preview/:contact_id" element={<StatementAnalysisPreview />} />
        <Route path="/statement/analysis_preview_committee/:contact_id" element={<StatementAnalysisPreviewCommittee />} />
        <Route path="/statement/loan_analysis_preview/:contact_id" element={<LoanStatementAnalysisPreview />} />
        <Route path="/statement/loan_analysis_preview_committee/:contact_id" element={<LoanStatementAnalysisPreviewCommittee />} />
        <Route path="/statement/analysis" element={<StatementAnalysis />} />
        <Route path="/statement/upload_manual/:contact_id" element={<UploadManualPage />} />
        <Route path="/statement/edit/:contact_id" element={<EditStatement />} />
        <Route path="/statement/file_management" element={<StatementFileManagement />} />

        <Route path="/statement/edit_manual/:statement_id" element={<EditKeyin />} />
        <Route path="/statement/edit_ocr/:statement_id" element={<EditOcr />} />

        {/* statement template*/}
        <Route path="/statement_analysis_quick" element={<StatementQuick />} />
        {/* direct_credit */}
        <Route path="/direct_credit/transfer_waiting" element={<DCTransferWaiting />} />
        <Route path="/direct_credit/transfer_ready" element={<DCTransferReady />} />
        <Route path="/direct_credit/transfer_success" element={<DCTransferSuccess />} />
        <Route path="/direct_credit/operator" element={<DCCreditOper />} />
        <Route path="/direct_credit/operator/detail" element={<DCCreditOperDetail />} />

        {/* direct_debit */}
        <Route path="/direct_debit/customer_list" element={<DDCustomerList />} />
        <Route path="/direct_debit/customer_details" element={<DDCustomerDetails />} />

        <Route path="/direct_debit/pay_success_page" element={<DDPaySuccessPage />} />
        <Route path="/direct_debit/pay_success_export" element={<DDPaySuccessExport />} />
        <Route path="/direct_debit/pay_unsuccess_page" element={<DDPayUnsuccessPage />} />
        <Route path="/direct_debit/manual_process_page" element={<DDManualProcessPage />} />
        <Route path="/direct_debit/report" element={<DirectDebitReport />} />
        <Route path="/direct_debit/auto_partial" element={<DDAutoPartial />} />

        {/* <Route path="/direct_debit/customer_list_compare" element={<DDCustomerListCompare />} /> */}

        {/* <Route path="/file_management" element={<FileManagement />} /> */}

        {/* NCB */}
        <Route path="/ncb/list/:contact_id" element={<List />} />
        <Route path="/ncb/:detail/:contact_id" element={<Detail />} />
        <Route path="/ncb/summarize/:contact_id" element={<Summarize />} />
        <Route path="/ncb/table/:contact_id" element={<NCBTable />} />


        {/* statement */}
        {/* <Route path="/statement/customers_list" element={<StatementCustomersList />} /> */}
        <Route path="/statement/files_list/:contact_id/:citizen_id" element={<StatementFilesList />} />
        <Route path="/statement/analysis" element={<StatementAnalysis />} />
        <Route path="/statement/upload_manual/:contact_id" element={<UploadManualPage />} />
        <Route path="/statement/edit/:contact_id" element={<EditStatement />} />
        {/* statement template*/}
        <Route path="/statement_analysis_quick" element={<StatementQuick />} />

        {/* collector */}

        <Route path="/contact_management" element={<ContactManagement />} />
        <Route path="/contact_collector" element={<ContactCollector />} />
        <Route path="/contact_detail/:id" element={<ContactDetail />} />

        <Route path="/test" element={<Dev />} />
        <Route path="/404" element={<NotFound />} />

        <Route path="/bank/change" element={<BankChange />} />
        <Route path="/marketing/view" element={<Marketing />} />
        <Route path="/marketing/tracking" element={<MarketingTracking />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;