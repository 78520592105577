import * as React from 'react';
import { useNavigate } from "react-router-dom";
import * as userHttpServices from '../../services/services';
import * as tackingHttpServices from '../../services/tacking_service';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Outlet, Link } from "react-router-dom";
import { getCommitteeSummaryList } from '../../services/committee';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,

};
const style_report = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const style_assign = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
const headCells = [
    {
        id: 'round_follow',
        disablePadding: false,
        width: 220,
        label: 'รอบการติดตามเอกสาร',
    },
    {
        id: 'follow',
        disablePadding: false,
        width: 150,
        label: 'ติดตามครั้งที่',
    },
    {
        id: 'grade',
        disablePadding: false,
        width: 150,
        label: 'วันที่ลูกค้าสมัคร',
    },
    {
        id: 'name',
        disablePadding: false,
        width: 180,
        label: 'ชื่อ-นามสกุล',
    },
    {
        id: 'brand',
        disablePadding: false,
        width: 180,
        label: 'Brand',
    },
    {
        id: 'product',
        disablePadding: false,
        width: 180,
        label: 'Product',
    },
    
    {

        id: 'phone_number',
        disablePadding: false,
        width: 180,
        label: 'เบอร์โทรศัพท์',
    },
    {
        id: 'date_pass',
        disablePadding: false,
        width: 220,
        label: 'วันที่ติดตามล่าสุด'
    },
    {
        id: 'action',
        width: 200,
        label: 'Action ล่าสุด',
    },

    {
        id: 'action',
        width: 80,
        label: 'Action',
    },
    {
        id: 'date_receipt',
        disablePadding: false,
        width: 200,
        label: 'วันที่แก้ไขเอกสารล่าสุด',
    },
    // {
    //     id: 'status',
    //     width: 180,
    //     label: 'สถานะเอกสาร',
    // },
    {
        id: 'form',
        width: 10,
        label: 'ดูฟอร์มสมัคร',
    },
    {
        id: 'form_download',
        width: 10,
        label: 'โหลดฟอร์มสมัคร',
    },
    {
        id: '',
        width: 10,
        label: 'เพิ่มเติม',
    },

];
const columns = [
    // { field: 'collection_date', headerName: 'วันที่', width: 300, editable: false },
    { field: 'action_name', headerName: 'Action', width: 300, editable: false },
    { field: 'count_action', headerName: 'จำนวน', width: 100, editable: false },
    { field: 'com', headerName: 'เอกสาร ครบ', width: 100, editable: false },
    { field: 'notcom', headerName: 'เอกสาร ไม่ครบ', width: 150, editable: false },
];
function MyApp() {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('collection_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows_data, setRowData] = React.useState([]);
    const [assign_date, setAssignDate] = React.useState(new Date());
    const [opened, setOpened] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [rows_count, setRowsCount] = React.useState(0);


    const [status, setStatus] = React.useState(false)
    const [rowsPerFilter, setRowsPerFilter] = React.useState(1)
    const [value, setValue] = React.useState([]);
    const [tag, setTag] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [open_report, setOpenReport] = React.useState(false);
    const [action_report, setActionReport] = React.useState(false);
    const [start_report, setStartReport] = React.useState(new Date());
    const [end_report, setEndReport] = React.useState(new Date());
    const [report, setReport] = React.useState([]);
    const [open_assign, setOpenAssign] = React.useState(false);
    const [contract, setContarct] = React.useState(null);
    const [action_type, setActionType] = React.useState(null);
    const [upload_count, setUploadCount] = React.useState(null);
    const handleClose = () => setOpen(false);
    const handleCloseReport = () => setOpenReport(false);
    const handleCloseAssign = () => setOpenAssign(false);
    let token = localStorage.getItem('token_org')

    const { enqueueSnackbar } = useSnackbar();

    //filter
    const [operator, setOperater] = React.useState([true, true, true, true, true, true, true])
    const [filter, setFilter] = React.useState(true)
    // console.clear();
    const confirm = useConfirm();
    const [date, setDate] = React.useState(null)
    const [name_filter, setNameFilter] = React.useState('');
    const prepRowData = async (row) => {
        let return_list = []
        // let committee_data = await getCommitteeSummaryList()
        // console.log("committee_data", committee_data.data.data);
        // for(let item of row){
        //     // item
        //     let in_committee_summary = committee_data.data.data.documents.find(
        //         (cd) => cd.citizen_id === item.user_id
        //     );
        //     // console.log("in_committee_summary", in_committee_summary);
        //     if(in_committee_summary === undefined){
        //         return_list.push({
        //             'is_in_committee' : false,
        //             ...item
        //         })
        //     }else{
        //         return_list.push({
        //             'is_in_committee' : true,
        //             ...item
        //         })
        //     }
        // }
        setRows(row);
    }
    React.useEffect(() => {
        setRows([])
        setStatus(false)
        setSelected([])
        setTag(false)
        let test = []
        let object = {
            "pagination": {
                "page": page,
                "rowsPerPage": rowsPerPage,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }
        tackingHttpServices.getTackinglist(token, object)
            .then((res) => {

                console.log(res);
                if (res.data.data.length == 0) {
                    setRows([])
                } else {
                    setStatus(true)
                    prepRowData(res.data.data.rows)
                    // setRows(res.data.data.rows)
                    setRowsCount(res.data.data.rowcount)

                }

            })
    }, [filter])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            checked={tag}
                            onChange={(_, tag) => {
                                setTag(tag)
                                onSelectAllClick(_, tag)
                            }}

                            inputProps={{
                                'aria-label': 'select all',
                            }}
                        // disabled
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            console.log(selected)
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            // console.log(withoutDuplicates)
            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    let test = []
    const handleGetData = (newPage = null, newRowPage = null) => {
        let object = {
            "pagination": {
                "page": newPage === null ? page : newPage,
                "rowsPerPage": newRowPage === null ? rowsPerPage : newRowPage,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }
        if (newPage > page) {
            let sum = newPage == 0 ? rowsPerPage : (newPage + 1) * rowsPerPage
            if (rows.length < sum)
                tackingHttpServices.getTackinglist(token, object)
                    .then((res) => {

                        if (res.data.data.length == 0) {
                            setRows([])
                        } else {
                            setStatus(true)
                            let sum_row = rows.concat(res.data.data.rows)
                            console.log(sum_row.length)
                            setRows(sum_row)
                            setRowsCount(res.data.data.rowcount)
                        }

                    })
        }

    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleGetData(newPage)

    };
    const nameFilter = (event) => {
        setNameFilter(event.target.value)
    }
    const handleChangeRowsPerPage = (event) => {
        // handleGetData(null ,parseInt(event.target.value, 10))
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const getResetFilter = () => {
        let object = {
            "pagination": {
                "page": 0,
                "rowsPerPage": rowsPerPage,
                "sortBy": "",
                "descending": true
            },
            "filters": []
        }
        tackingHttpServices.getTackinglist(token, object)
            .then((res) => {
                if (res.data.data.length == 0) {
                    setRows([])
                } else {
                    setStatus(true)
                    setRows(res.data.data.rows)
                    // setRows(res.data.data.rows)
                    setRowsCount(res.data.data.rowcount)
                }
            })

    }

    const addFilter = () => {
        if (rowsPerFilter < 5) {
            setRowsPerFilter(rowsPerFilter + 1)
        }
    }

    const handleChangeOperator = (index) => (e) => {
        let newOperator = []
        newOperator = [...operator]
        newOperator[index] = e.target.value
        setOperater(newOperator)
    }

    const delFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
        if (rowsPerFilter > 1) {
            setRowsPerFilter(rowsPerFilter - 1)
        }
    }

    const resetFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
    }

    const resetFilter = (valueIndex) => {
        switch (valueIndex) {
            case 1:
                getResetFilter()
                break;
            default:
            // code block
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filterActionGrid = (index) => {
        if (value[index] == 1) {
            return (
                <>
                    <Grid item>
                        <TextField id="outlined-basic" label="Name" variant="outlined" value={name_filter} onChange={nameFilter} />
                    </Grid>
                </>
            )
        }
    }
    const filterGrid = (index) => {
        return (
            <>
                <Grid item >
                    <FormControl style={{ width: 300 }} >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value[index] === undefined ? '' : value[index]}
                            label="Filter"
                            onChange={valueX(index)}
                        >
                            <MenuItem value={1}>ชื่อ</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {filterActionGrid(index)}
                {/* 
                {
                    rowsPerFilter !== index + 1 ?
                        <Grid item>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                <Select
                                    style={{ width: 100 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={operator[index]}
                                    label="Operator"
                                    onChange={handleChangeOperator(index)}
                                >
                                    <MenuItem value={true}>และ</MenuItem>
                                    <MenuItem value={false}>หรือ</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        ''
                } */}

                {
                    value[index] !== undefined ?
                        <Grid item>
                            <Tooltip title="ล้างตัวกรอง">
                                <IconButton onClick={() => resetFilterAction(index)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :
                        ''
                }
                <Grid item marginRight={10}>
                    <Tooltip title="ลบตัวกรองข้อมูล">
                        <IconButton onClick={() => delFilterAction(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        )
    }
    const valueX = (index) => (event) => {
        const filtered = value.filter(obj => {
            return obj === event.target.value;
        });
        if (filtered.length === 0) {

            resetFilter(value[index])

            let newValue = []
            newValue = [...value]
            newValue[index] = event.target.value
            setValue(newValue)
        } else {

        }
    };
    const onClickFilterAction = () => {
        const filter = name_filter.replace(/\s/g, "");
        if (name_filter != '') {
            let object = {
                "filters": filter
            }
            tackingHttpServices.getTackinglist(token, object)
                .then((res) => {
                    if (res.data.data.length == 0) {
                        setRows([])
                    } else {
                        setStatus(true)
                        setRows(res.data.data.rows)
                        setRowsCount(res.data.data.rowcount)
                    }
                })
        } else {
            getResetFilter()
        }
    }
    const display_action = (action) => {
        switch (action) {
            case 1:
                return <div>นัด Upload เอกสารเพิ่มเติม</div>
            case 5:
                return <div>ยกเลิก</div>
            case 2:
                return <div>ติดต่อไม่ได้/ไม่รับสาย</div>
            case 3:
                return <div>นัดส่งเอกสาร consent กระดาษ</div>
            case 4:
                return <div>นัดส่งเอกสาร consent ออนไลน์</div>
            case 8:
                return <div>ขอดูรายละเอียด</div>
            case 0:
                return <div>-</div>
            case null:
                return <div>-</div>
        }
    }
    const routeChangeEdit = (rowdata) => {
        const parameterData = { contact_id: rowdata.contact_id, user_id: rowdata.user_id, name: rowdata.name };
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/document_tracking_user?' + queryParams, '_blank');

    };
    const routeChangeForm = (rowdata, type) => {

        let obj = {
            user_id: rowdata.user_id,
            contact_id: Number(rowdata.contact_id),
            brand: rowdata.brand,
            type: type
        }

        tackingHttpServices.getForm(token, obj)
            .then((res) => {
                console.log(res)
                if (1 == 1) {
                    var myParam = res.data.data;
                    // console.log();
                    window.open('/preview?param=' + encodeURIComponent(myParam), '_blank');
                } else {
                    alert('ลูกกค้ากำลังอยู่ระหว่างการสมัคร กรุณาลองอีกครั้งภายหลัง')
                }
            })
    }
    const getFormDownload = (rowdata, type) => {
        let obj = {
            user_id: rowdata.user_id,
            contact_id: Number(rowdata.contact_id),
            type: type
        }
        tackingHttpServices.getForm(token, obj)
            .then((res) => {
                var myParam = res.data.data;
                window.open(myParam)

            })
    }

    const addDate = (row, action_type) => {
        setRowData(row)
        setOpenAssign(true)
        setActionType(action_type)
    }
    const handleEdit = (row, status) => {

        if (row == 'add_date') {
            var obj = {
                user_id: rows_data.user_id,
                contact_id: rows_data.contact_id,
                action_type: status,
                old_action_type: rows_data.action_type,
                id: rows_data.id,
                assign_date: new Date(assign_date)
            }
        } else {
            var obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: status,
                old_action_type: row.action_type,
                id: row.id
            }
        }
        confirmAlert({
            title: 'ยืนยันที่จะอัพเดทสถานะ?',
            message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        tackingHttpServices.updateCollectorStatus(token, obj)
                            .then((res) => {
                                setRowData([])
                                window.alert('อัพเดทสถานะสำเร็จ')
                                window.location.reload();
                            })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })


    }

    const onClickApprove = (row, status) => {

        if (status == 4 && open == false) {
            let obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: row.action_type,
                id: row.id
            }
            setRowData(obj)
            setOpen(true)
        } else {
            // var today = new Date(date)
            let obj = {
                user_id: rows_data.user_id,
                contact_id: rows_data.contact_id,
                action_type: rows_data.action_type,
                id: rows_data.id,
                contract_no: contract,
                date_approve: date
            }
            confirmAlert({
                title: 'ยืนยันที่จะบันทึกการอนุมัติ?',
                message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            tackingHttpServices.approveFileContract(token, obj)
                                .then((res) => {
                                    console.log(res)
                                    window.alert('บันทึกสำเร็จ')
                                    setRowData([])
                                    window.location.reload();

                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        }
    }
    const handleGetReport = (row, status) => {

        setOpenReport(true)

    }

    const handleupdateRegisterType = (row, product) => {
        let obj = {
            user_id: row.user_id,
            contact_id: row.contact_id,
            product: product,
        }
        confirmAlert({
            title: `ยืนยันที่จะเปลี่ยน Product ลูกค้าเป็น ${product}?`,
            message: 'คุณแน่ใจหรือที่จะอัพเดท',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        if (row.product === product) {
                            alert('อัพเดทสำเร็จ')
                        } else {
                            tackingHttpServices.UpdateProduct(token, obj)
                                .then((res) => {
                                    if (res.data.success) {
                                        alert('เปลี่ยน product สำเร็จ')
                                        window.location.reload();
                                    } else {
                                        alert('เปลี่ยน product ไม่สำเร็จ')
                                        window.location.reload();
                                    }
                                })
                        }
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }
    const handleupdateBrand = (row, brand) => {
        let obj = {
            user_id: row.user_id,
            contact_id: row.contact_id,
            brand: brand,
        }
        confirmAlert({
            title: `ยืนยันที่จะเปลี่ยน Brand ลูกค้าเป็น ${brand}?`,
            message: 'คุณแน่ใจหรือที่จะอัพเดท',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        if (row.brand === brand) {
                            alert('อัพเดทสำเร็จ')
                        } else {
                            tackingHttpServices.UpdateBrand(token, obj)
                                .then((res) => {
                                    if (res.data.success) {
                                        alert('เปลี่ยน Brand สำเร็จ')
                                        window.location.reload();
                                    } else {
                                        alert('เปลี่ยน Brand ไม่สำเร็จ')
                                        window.location.reload();
                                    }
                                })
                        }
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }
    const onGetReport = () => {
        let obj = {
            action_report: action_report,
            start_report: start_report,
            end_report: end_report
        }
        tackingHttpServices.getReport(token, obj)
            .then((res) => {
                var data = res.data.data.report
                var count = res.data.data.upload_count
                console.log("report",data)
                setReport(data)
                setUploadCount(count)
            })
    }
    return (
        <Box sx={{ width: '100%' }}>
            {/* {
                rows.length === 0 && !status ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            } */}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <Grid display={opened ? 'none' : ''}>
                    {
                        rowsPerFilter > 4 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(4)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 3 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(3)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 2 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(2)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 1 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(1)}
                            </Grid>
                            : null
                    }
                    <Grid container spacing={2} style={{ padding: '1rem' }}>
                        {filterGrid(0)}
                        {/* <Grid item >
                            <Button variant="outlined" style={{ color: "#333", borderColor: "#333" }} onClick={() => addFilter()}>เพิ่มช่องการค้นหา</Button>
                        </Grid> */}
                        <Grid item >
                            <Button variant="contained" onClick={() => onClickFilterAction()}>ค้นหา</Button>
                        </Grid>
                        <Grid item >
                            <Button variant="contained" color="success" onClick={() => handleGetReport()} style={{ marginLeft: '5rem' }}>Report</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        // sx={{ minWidth: 750 }}
                        // aria-labelledby="tableTitle"
                        // size='medium'
                        stickyHeader aria-label="sticky table"
                    >
                        {/* <Table stickyHeader aria-label="sticky table"> */}
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    // if (row.action_type !== 5 && row.round < 4) {
                                    if (row.action_type !== 5) {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.collection_date.split("T")[0]}</TableCell>
                                                <TableCell>{row.round}</TableCell>
                                                <TableCell >{row.create_date == null ? '-' : row.create_date.split("T")[0]}</TableCell>
                                                <TableCell>{row.name + " " + row.surname}</TableCell>
                                                <TableCell style={{ textAlign: 'center', width: 250 }}>
                                                    <Select
                                                        value={row.brand}
                                                        // onChange={handleupdateBrand}
                                                        size="small"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        style={{ width: 250 }}
                                                        disabled={row.status === 3 ||  row.is_in_committee ? true : false}
                                                    >
                                                        <MenuItem value="PICO" onClick={() => handleupdateBrand(row, 'PICO')}>PICO</MenuItem>
                                                        <MenuItem disabled value="MOU-GLOBALSERVICE" onClick={() => handleupdateBrand(row, 'MOU-GLOBALSERVICE')}>MOU-GLOBALSERVICE</MenuItem>
                                                        <MenuItem disabled value="NTK-Dauto" onClick={() => handleupdateBrand(row, 'NTK-Dauto')}>NTK-D auto</MenuItem>

                                                    </Select>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', width: 250 }}>
                                                    <Select
                                                        value={row.product}
                                                        // onChange={handleupdateBrand}
                                                        size="small"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        style={{ width: 250 }}
                                                        disabled={row.status === 3 ||  row.is_in_committee ? true : false}
                                                    >
                                                        <MenuItem 
                                                            value="PLOAN" 
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN')
                                                            }
                                                        >
                                                            รายย่อยปกติ
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value="PLOAN-NONCB" 
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-NONCB')
                                                            }
                                                        >
                                                            รายย่อยปกติ(ไม่ตรวจ NCB)
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value="PLOAN-ALL" 
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-ALL')
                                                            }
                                                        >
                                                            สินเชื่อรวมหนี้
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value="PLOAN-MOU" 
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-MOU')
                                                            }
                                                        >
                                                            สินเชื่อ MOU
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value="NANO" 
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'NANO')
                                                            }
                                                        >
                                                            สินเชื่อนาโนไฟแนนซ์
                                                        </MenuItem>
                                                    </Select>
                                                    
                                                </TableCell>
                                                <TableCell>{row.phone_number}</TableCell>
                                                <TableCell>{row.action_date == null ? '-' : row.action_date.split("T")[0]}</TableCell>
                                                <TableCell>{display_action(row.action_type)}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}><Select
                                                    // value={row.action_type}
                                                    // onChange={handleChange}
                                                    size="small"
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ width: 150 }}
                                                >
                                                    <MenuItem><em>Action</em></MenuItem>
                                                    <MenuItem value={2} onClick={() => addDate(row, 2)}>ติดต่อไม่ได้/ไม่รับสาย</MenuItem>
                                                    <MenuItem value={5} onClick={() => handleEdit(row, 5)}>ยกเลิก</MenuItem>
                                                    <MenuItem value={1} onClick={() => addDate(row, 1)}>นัด Upload เอกสารเพิ่มเติม</MenuItem>
                                                    <MenuItem value={3} onClick={() => addDate(row, 3)}>นัดส่งเอกสาร consent กระดาษ</MenuItem>
                                                    {/* <MenuItem value={4} onClick={() => addDate(row, 4)}>นัดส่งเอกสาร consent ออนไลน์</MenuItem> */}
                                                    <MenuItem value={7} onClick={() => handleEdit(row, 7)}>เอกสารครบ/ส่งเรื่องให้ฝ่ายสินเชื่อ</MenuItem>
                                                    <MenuItem value={8} onClick={() => addDate(row, 8)}>ขอดูรายละเอียด</MenuItem>
                                                </Select></TableCell>
                                                <TableCell>{row.file_update_date == null ? "-" : row.file_update_date.split("T")[0]}</TableCell>
                                                {/* <TableCell>{display_status(row.status)}</TableCell> */}
                                                <TableCell><IconButton onClick={(e) => routeChangeForm(row, 'PICO')}><SearchIcon color="primary" /></IconButton></TableCell>
                                                <TableCell><IconButton onClick={(e) => getFormDownload(row, 'PICO')}><SaveAltIcon color="primary" /></IconButton></TableCell>
                                                <TableCell><IconButton onClick={(e) => routeChangeEdit(row)} target="_blank"><SearchIcon color="primary" /></IconButton></TableCell>
                                                {/* <TableCell> <Link to="/document_tracking_user" state={{ contact_id: row.contact_id, user_id: row.user_id }} target="_blank"><IconButton><SearchIcon color="primary" /></IconButton></Link></TableCell> */}
                                                {/* <TableCell> <Link to={newTabURL}  target="_blank"><IconButton><SearchIcon color="primary" /></IconButton></Link></TableCell> */}

                                                {/* <Link to={{ pathname: '/foo', query: { foo: "bar" } }}/> */}
                                            </TableRow>
                                        );
                                    }

                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows_count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2}}
            >   
                <Box sx={style}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                   
                    <h2 id="app">ยืนยันการผ่านการอนุมัติ</h2>

                    <TextField style={{ marginBottom: '2rem', width: 230 }} id="outlined-basic2" label="เลขที่สัญญา" variant="outlined" size="small" onChange={(e) => setContarct(e.target.value)} />
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DesktopDatePicker
                            label="วันที่ผ่านการอนุมัติ"
                            inputFormat="YYYY-MM-DD"
                            value={date}
                            onChange={(date) => setDate(date)}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                    <br></br>
                    <Button style={{ float: 'right', marginTop: 10 }} variant="contained" onClick={() => onClickApprove()}>ยืนยัน</Button>
                </Box>
            </Modal> */}
            {/* report */}
            <Modal
                open={open_report}
                onClose={handleCloseReport}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_report}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenReport(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* {console.log(file_type)} */}
                    <h2 id="report">Report</h2>

                    <Grid container spacing={2} style={{ padding: '1rem' }}>
                        <Grid item >
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="วันที่เริ่มต้น"
                                    inputFormat="YYYY-MM-DD"
                                    value={start_report}
                                    onChange={(date) => setStartReport(date)}
                                    renderInput={(params) => <TextField size="small" {...params} />}

                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item >
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="วันที่สิ้นสุด"
                                    inputFormat="YYYY-MM-DD"
                                    value={end_report}
                                    onChange={(date) => setEndReport(date)}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />

                            </LocalizationProvider>
                        </Grid>

                        {/* <Grid item >
                            <FormControl style={{ marginLeft: 20 }}>
                                <InputLabel id="action-label">Action</InputLabel>
                                <Select
                                    labelId="action-label"
                                    id="demo-simple-select"
                                    value={action_report}
                                    label="Action"
                                    onChange={(e) => setActionReport(e.target.value)}
                                    size='small'
                                    style={{ width: 200 }}
                                >
                                    <MenuItem value={1} >ไม่รับสาย/ปิดเครื่อง</MenuItem>
                                    <MenuItem value={5} >ยกเลิก</MenuItem>
                                    <MenuItem value={3} >นัดส่งเอกสาร</MenuItem>
                                    <MenuItem value={4} >ผ่านการพิจารณา</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item>
                            <Button style={{ float: 'right' }} variant="contained" onClick={() => onGetReport()}>ค้นหา</Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <div>จำนวนคนที่สมัครใหม่ {upload_count}</div>
                        </Grid>
                    </Grid>
                    <Box sx={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={report}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Box>
            </Modal>

            {/* ass*/}
            <Modal
                open={open_assign}
                onClose={handleCloseAssign}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 2 }}
            >
                <Box sx={style_assign}>
                    <IconButton
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={() => setOpenAssign(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* {console.log(file_type)} */}
                    <h2 id="assign">เลือกวันนัดส่งเอกสาร</h2>
                    <Grid container spacing={2} style={{ paddingBottom: '1rem' }}>
                        <Grid item >
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="วันที่นัด"
                                    inputFormat="DD-MM-YYYY"
                                    value={assign_date}
                                    onChange={(assign_date) => setAssignDate(assign_date)}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                    isRequired={true}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Button style={{ float: 'right' }} variant="contained" onClick={() => assign_date === null ? window.alert('กรุณาเลือกวันที่ก่อนบันทึกข้อมูล') : handleEdit('add_date', action_type)}>บันทึกวันนัดหมาย</Button>
                </Box>
            </Modal >
        </Box >
    );
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <MyApp />
        </SnackbarProvider>
    );
}

