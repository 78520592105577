import axios from "axios";
// import { callback } from "chart.js/dist/helpers/helpers.core.js";
import jwt_decode from "jwt-decode";

const config = require('../config.js')

let ROOT_URL =config.FILE_API_URL

export const getContactID = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/hubspot/contacts/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getContactData = (obj, callback) =>{
    return axios.post(`${ROOT_URL}/hubspot/contracts`, obj, {
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // },
    })
}