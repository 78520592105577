import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Grid,
    Box,
    Typography,
    Button,
    Paper,
    TextField,
    FormControlLabel,
    Switch,
} from '@mui/material';
import SideBar from '../dashboard.js';
import jwt_decode from 'jwt-decode';
import { DataGrid } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    LocalizationProvider,
    DesktopDatePicker,
} from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import Loading from '../../components/loading.js'
import { confirmAlert } from 'react-confirm-alert';

import * as statementHttpServices from '../../services/statement_service.js'

export default function App() {
    const { statement_id } = useParams();
    let token = localStorage.getItem('token')
    let decoded
    const [isLoading, setIsLoading] = useState(true);

    // const [statement, setStatement] = useState()
    const [rows, setRows] = useState([])
    const [bank, setBank] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    const handleStateDateChange = (e) => {
        const newDate = new Date(e);
        setStartDate(newDate);
    };

    const handleEndDateChange = (e) => {
        const newDate = new Date(e);
        setEndDate(newDate);
    };

    useEffect(() => {
        statementHttpServices.getKeyinStatement(statement_id)
            .then((res) => {
                const data = res.data.data[0];
                const startDate = new Date(data.data.account_info.startDate.replace(/(\d+[/])(\d+[/])/, '$2$1'));
                const endDate = new Date(data.data.account_info.endDate.replace(/(\d+[/])(\d+[/])/, '$2$1'));

                setStartDate(startDate);
                setEndDate(endDate);
                setRows(data.data.account_statement[0]);
                setBank(data.data.account_info.bank)

                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        const generateRows = () => {
            const rows = [];

            for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
                rows.push({
                    id: rows.length + 1,
                    date: currentDate.toLocaleDateString("en-GB"),
                    balance: 0,
                    withdraw: 0,
                    excludedMoney: 0,
                    excludedMoneyTotal: 0,
                    note: null,
                    finalBalance: 0,
                    haveExcluded: false
                });
            }

            return rows;
        };

        const newRow = generateRows();
        const oldRow = rows

        let excludedMoneyTotal = 0

        const updatedRow = newRow.map(item1 => {
            const matchingItem = oldRow.find(item2 => item2.date === item1.date);
            if (matchingItem) {
                excludedMoneyTotal = matchingItem.excludedMoneyTotal
                return {
                    ...item1,
                    balance: matchingItem.balance,
                    withdraw: matchingItem.withdraw,
                    excludedMoney: matchingItem.excludedMoney,
                    excludedMoneyTotal: matchingItem.excludedMoneyTotal,
                    note: matchingItem.note,
                    finalBalance: matchingItem.finalBalance,
                    haveExcluded: matchingItem.haveExcluded
                };
            } else {
                return {
                    ...item1,
                    excludedMoneyTotal: excludedMoneyTotal,
                    finalBalance: item1.balance - excludedMoneyTotal,
                }
            }
        });

        console.log('updatedRow', updatedRow)

        setRows(updatedRow);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    const updatedItems = (row, e) => {
        const withdraw = row.withdraw
        const isCheckboxChecked = e.target.checked;
        if (isCheckboxChecked) {
            const updated = rows.map((item) => {
                if (item.id === row.id) {
                    return { ...item, excludedMoney: item.withdraw, excludedMoneyTotal: item.excludedMoneyTotal + withdraw, finalBalance: item.balance - row.excludedMoneyTotal - withdraw, haveExcluded: true }
                } else if (item.id > row.id) {
                    return { ...item, excludedMoneyTotal: item.excludedMoneyTotal + withdraw, finalBalance: item.balance - item.excludedMoneyTotal - withdraw };
                } else {
                    return item
                }
            })
            setRows(updated)
        } else {
            const updated = rows.map((item) => {
                if (item.id === row.id) {
                    return { ...item, excludedMoney: 0, excludedMoneyTotal: item.excludedMoneyTotal - withdraw, finalBalance: item.balance - item.excludedMoneyTotal + withdraw, haveExcluded: false }
                } else if (item.id > row.id) {
                    return { ...item, excludedMoneyTotal: item.excludedMoneyTotal - withdraw, finalBalance: item.balance - item.excludedMoneyTotal + withdraw };
                } else {
                    return item
                }
            })
            setRows(updated)
        }
    };

    const columns = [
        {
            field: 'date',
            headerName: 'วันที่',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'balance',
            headerName: 'เงินคงเหลือสิ้นวัน*',
            type: 'number',
            width: 250,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'withdraw',
            headerName: 'เงินเข้า*',
            type: 'number',
            width: 250,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'haveExcluded',
            headerName: '',
            width: 55,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) =>
                row.withdraw > 0 && <FormControlLabel
                    control={
                        <Switch checked={row.haveExcluded} onChange={(e) => updatedItems(row, e)} />
                    }
                />
        },
        {
            field: 'excludedMoney',
            headerName: 'เงินที่ไม่นำมาคิด',
            type: 'number',
            width: 250,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => row.withdraw > 0 && row.haveExcluded && parseInt(row.withdraw).toLocaleString('en-US')
        },
        {
            field: 'note',
            headerName: 'หมายเหตุ*',
            minWidth: 250,
            flex: 1,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'finalBalance',
            headerName: 'เงินคงเหลือสิ้นวัน ปรับปรุง',
            type: 'number',
            width: 250,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
    ];

    function handleEditCellChange(params) {
        let newBalance = 0
        const updated = rows.map((item) => {
            if (item.id === params.id) {
                switch (params.field) {
                    case 'balance':
                        newBalance = params.value
                        break;
                    case 'withdraw':
                        newBalance = item.balance
                        if (item.haveExcluded) {
                            return item
                        }
                        break;
                    default:
                        newBalance = item.balance
                }
                //new
                return { ...item, [params.field]: params.value, finalBalance: newBalance - item.excludedMoneyTotal }

            } else if (item.id > params.id) {
                return { ...item, finalBalance: item.balance - item.excludedMoneyTotal };
            } else {
                return item
            }
        })
        setRows(updated);
    }

    if (isLoading) {
        return <Loading />; // Display loading message or spinner
    }

    return (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Statement Manual Key in</Typography>
                </Grid>
            </Grid>

            <Paper elevation={3}>
                <Grid container padding={2} marginBottom={1}>
                    <Grid item xs>
                        <Typography variant='h6'></Typography>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            value={bank}
                            onChange={(event, newValue) => {
                                setBank(newValue);
                            }}
                            id="autocomplete-bank"
                            options={[
                                { th_name: 'ธ.กสิกร', en_name: 'kbank' },
                                { th_name: 'ธ.กรุงเทพ', en_name: 'bbl' },
                                { th_name: 'ธ.ไทยพาณิชย์', en_name: 'scb' }
                            ]}
                            getOptionLabel={(option) => option.th_name}
                            isOptionEqualToValue={(option, value) => option.en_name === value.en_name}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField {...params} label="ธนาคาร" size='small' />}
                        />
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                size='small'
                                label="วันเริ่มต้น"
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(e) => handleStateDateChange(e)}
                                renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} size='small' />}
                                maxDate={endDate}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="วันสิ้นสุด"
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                onChange={(e) => handleEndDateChange(e)}
                                renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} size='small' />}
                                minDate={startDate}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        '& .red': {
                            backgroundColor: '#ff0200',
                            color: '#ffffff',
                        },
                        '& .green': {
                            backgroundColor: '#70ad46',
                            color: '#ffffff',
                        },
                        '& .palegreen': {
                            backgroundColor: '#b5d7a8',
                            // color: '#ffffff',
                        },
                        '& .orange': {
                            backgroundColor: '#ed7c30',
                            color: '#ffffff',
                        },

                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        onCellEditCommit={(params, e) => handleEditCellChange(params, e)}

                        getCellClassName={(params) => {
                            if (params.field === 'balance') {
                                return params.value < 500 ? 'red' : 'green';
                            }
                            else if (params.field === 'withdraw') {
                                return 'palegreen';
                            }
                            else if (params.field === 'excludedMoney') {
                                return params.value !== 0 ? 'orange' : '';
                            }
                            else if (params.field === 'finalBalance') {
                                return params.value < 500 ? 'red' : 'green';
                            }
                        }}
                    />
                    <div style={{ padding: '15px', textAlign: 'right' }}>
                        <Button variant="contained" color={'success'} sx={{ width: '100px' }} onClick={() => handleSave()} disabled={bank === null}>SAVE</Button>
                    </div>
                </Box>
            </Paper>
        </div >
    )

    function handleSave() {
        confirmAlert({
            title: 'บันทึกข้อมูล',
            message: 'คุณแน่ใจหรือไม่ว่าต้องการบันทึกข้อมูล?',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        const body = {
                            statementId: statement_id,
                            data: {
                                "account_info": {
                                    "bank": bank,
                                    "startDate": startDate.toLocaleDateString("en-GB"),
                                    "endDate": endDate.toLocaleDateString("en-GB"),
                                },
                                "account_statement": [rows],
                                "timestamp": new Date()
                            }
                        }

                        statementHttpServices.editKeyinStatement(body)
                            .then((response) => {
                                console.log("Statement edited successfully:", response);
                                window.history.back();
                            })
                            .catch((error) => {
                                console.error("Error editing statement:", error);
                            });
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("cancel")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }

}