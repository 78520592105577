/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as userHttpServices from './../../../src/services/services';
import * as directDebitHttpService from '../../services/direct_debit'

import { VscCheck, VscChromeClose } from "react-icons/vsc";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'ActionOnDate',
        label: 'รอบการทวง',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'ActionCount',
        label: 'ทวงครั้งที่',
        width: 90,
        maxwidth: 90
    },
    {
        id: 'ActionCount2',
        label: 'เลขที่สัญญา',
        width: 90,
        maxwidth: 90
    },
    {
        id: 'Ku_name',
        label: 'ผู้กู้',
        width: 150,
        maxwidth: 150
    },
    {
        id: 'ProdName',
        label: 'ประเภทลูกหนี้',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'PolicyGroup',
        label: 'กลุ่มสัญญา',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'ENFollowup',
        label: 'พนักงานออกสัญญา',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'TodayBalance',
        label: 'เงินต้นคงเหลือ',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'InterestRemaining',
        label: 'ดอกเบี้ยคงเหลือ',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'PPP',
        label: 'ยอดผ่อน',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'PaymentRemaining',
        label: 'งวดคงเหลือ',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'Budget',
        label: 'ชั้นหนี้ค้าง',
        width: 100,
        maxwidth: 100
    },
    {
        id: 'remark_pay',
        label: 'รายการพักเงิน',
        width: 110,
        maxwidth: 100
    },
    {
        id: 'action',
        label: 'Action',
        width: 150,
        maxwidth: 150
    },
    {
        id: 'ActionNewOnDate',
        label: 'การทวงรอบถัดไป',
        width: 150,
        maxwidth: 150
    },
    {
        id: 'last_pay',
        label: 'วันที่จ่ายล่าสุด',
        width: 150,
        maxwidth: 150
    },
    {
        id: 'regis_dd',
        label: 'สมัคร DD',
        width: 20,
        maxwidth: 20,
    },
    {
        id: 'ddb',
        label: 'DIRECT DEBIT',
        width: 20,
        maxwidth: 20,
    },
    {
        id: 'ddb_action',
        label: 'ตัดเงิน',
        width: 20,
        maxwidth: 20
    }, {
        id: 'search',
        label: '',
        width: 20,
        maxwidth: 20
    },

];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                        disabled
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='center'
                        padding='normal'
                        width={headCell.width}
                        minwidth={headCell.minwidth}
                        maxwidth={headCell.maxwidth}
                        sortDirection={orderBy === headCell.id ? order : false}
                    // sortDirection={orderBy === headCell.ActionOnDate ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


function MyApp() {
    let token = localStorage.getItem('token_org')
    let success = localStorage.getItem('success')
    const moment = require('moment');
    const { enqueueSnackbar } = useSnackbar();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows, setRows] = React.useState([]);
    const [action, setAction] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [opened, setOpened] = React.useState(false);
    const [value, setValue] = React.useState([]);
    const [isDirectDebit, setIsDirectDebit] = React.useState([]);
    const [actionSwitch, setActionSwitch] = React.useState(true)
    //filter
    const [rowsPerFilter, setRowsPerFilter] = React.useState(1)
    const [date, setDate] = React.useState(null)
    const [filterAction, setFilterAction] = React.useState('')
    const [filterDate, setFilterDate] = React.useState(true)
    const [filter, setFilter] = React.useState(true)

    const [filterProdName, setFilterProdName] = React.useState('')
    //button
    const [disable_but, setDisable] = React.useState(true)

    React.useEffect(() => {
        setRows([])
        setStatus(false)
        setDisable(false)
        let filterDate
        if (date !== null) {
            let d = new Date(date);
            filterDate = moment(d).format('YYYY-MM-DD');
        }

        let test = []
        value.map((val, index) => {
            let attribute
            let operate = operator[index - 1] ? 'and' : 'or'
            let search

            switch (val) {
                case 3:
                    attribute = 'Ku_name'
                    search = test3
                    break;
                case 4:
                    attribute = 'ProdName'
                    search = filterProdName === '' ? test4 : filterProdName
                    break;
                case 5:
                    attribute = 'remark_pay'
                    search = test5
                    break;
                default:
                // code block
            }

            if (index === 0) { operate = 'and' }

            if (attribute) {
                test.push({
                    "operator": operate,
                    "attribute": attribute,
                    "search": search
                })
            }
        })
        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }
        userHttpServices.getCollector(token, object)
            .then((res) => {
                if (success) {
                    enqueueSnackbar('Record success!', { variant: 'success' });
                    localStorage.removeItem('success')
                }
                setStatus(true)
                setRows(res.data.data)

                directDebitHttpService.isDirectDebit()
                    .then((res) => {
                        setIsDirectDebit(res.data.data)
                    })
            })

        userHttpServices.getActionType(token,)
            .then((res) => {
                // console.log('action', res)
                setAction(res.data.data)
            })
    }, [filter])

    const [test3, settest3] = React.useState('');
    const [test4, settest4] = React.useState('');
    const [test5, settest5] = React.useState('');
    const [operator, setOperater] = React.useState([true, true, true, true, true])

    const handleChangeOperator = (index) => (e) => {
        let newOperator = []
        newOperator = [...operator]
        newOperator[index] = e.target.value
        setOperater(newOperator)
    }


    const hantest3 = (event) => {
        settest3(event.target.value)
    }

    const hantest4 = (event) => {
        settest4(event.target.value)
    }

    const handleFilterProdName = (event) => {
        setFilterProdName(event.target.value)
    }

    const hantest5 = (event) => {
        settest5(event.target.value)
    }

    const filterActionGrid = (index) => {
        //{/* <Grid item>
        //<TextField style={{ width: 300 }} id="outlined-basic" label="Search" variant="outlined" placeholder="Name, email, etc..." InputLabelProps={{ shrink: true }} />
        //</Grid> */}

        if (value[index] == 1) {
            return (
                <>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <MobileDatePicker
                                label="Date filter"
                                inputFormat="YYYY-MM-DD"
                                value={date}
                                onChange={handleChangeDate}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </>
            )
        } else if (value[index] == 2) {
            return (
                <>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Action filter</InputLabel>
                            <Select
                                style={{ width: 195 }}
                                labelId="select-label"
                                id="select"
                                value={filterAction}
                                label="Action filter"
                                onChange={handleFilterActionChange}
                            >
                                {/* <MenuItem value={1}>ทั้งหมด</MenuItem> */}
                                <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                                <MenuItem value={'true'}>ทำแล้ว</MenuItem>
                                <MenuItem value={'false'}>ยังไม่ได้ทำ</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )
        } else if (value[index] == 3) {
            return (
                <>
                    <Grid item>
                        <TextField id="outlined-basic" label="Name" variant="outlined" value={test3} onChange={hantest3} />
                    </Grid>
                </>
            )
        } else if (value[index] == 4) {
            return (
                <>
                    <Grid item >
                        <FormControl style={{ width: 195 }} >
                            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={test4}
                                label="Filter"
                                onChange={hantest4}
                            >
                                <MenuItem value={'สินเชื่อรายย่อยชั้นดี'}>สินเชื่อรายย่อยชั้นดี</MenuItem>
                                <MenuItem value={'สินเชื่อรายย่อยทั่วไป'}>สินเชื่อรายย่อยทั่วไป</MenuItem>
                                <MenuItem value={'สินเชื่อรายใหญ่'}>สินเชื่อรายใหญ่</MenuItem>
                                <MenuItem value={'อื่นๆ'}>อื่นๆ</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item display={test4 === 'อื่นๆ' ? '' : 'none'}>
                        <TextField id="filterProdName" label="search" variant="outlined" value={filterProdName} onChange={handleFilterProdName} />
                    </Grid>
                </>
            )
        } else if (value[index] == 5) {
            return (
                <>
                    <Grid item >
                        <FormControl style={{ width: 195 }} >
                            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={test5}
                                label="Filter"
                                onChange={hantest5}
                            >
                                <MenuItem value={'งวดปกติ'}>งวดปกติ</MenuItem>
                                <MenuItem value={'หักแล้ว'}>หักแล้ว</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )
        } else {
        }

    }

    const filterGrid = (index) => {
        return (
            <>
                <Grid item >
                    <FormControl style={{ width: 300 }} >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value[index] === undefined ? '' : value[index]}
                            label="Filter"
                            onChange={valueX(index)}
                        >
                            <MenuItem value={1}>รอบการทวง</MenuItem>
                            <MenuItem value={2}>สถานะของงาน</MenuItem>
                            <MenuItem value={3}>ผู้กู้</MenuItem>
                            <MenuItem value={4}>ประเภทลูกหนี้</MenuItem>
                            {/* <MenuItem value={5}>รายการพักเงิน</MenuItem> */}

                        </Select>
                    </FormControl>
                </Grid>
                {filterActionGrid(index)}

                {rowsPerFilter !== index + 1 ?
                    <Grid item>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                            <Select
                                style={{ width: 100 }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={operator[index]}
                                label="Operator"
                                onChange={handleChangeOperator(index)}
                            >
                                <MenuItem value={true}>และ</MenuItem>
                                <MenuItem value={false}>หรือ</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    :
                    ''
                }

                {
                    value[index] !== undefined ?
                        <Grid item>
                            <Tooltip title="ล้างตัวกรอง">
                                <IconButton onClick={() => resetFilterAction(index)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :
                        ''
                }

                <Grid item marginRight={10}>
                    <Tooltip title="ลบตัวกรองข้อมูล">
                        <IconButton onClick={() => delFilterAction(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        )
    }

    const onClickFilterAction = () => {
        setFilter(current => !current)
    }

    const handleChangeDate = (newDate) => {
        // console.log('newDate', newDate)
        setDate(newDate)
    }

    const valueX = (index) => (event) => {

        const filtered = value.filter(obj => {
            return obj === event.target.value;
        });

        if (filtered.length === 0) {
            let newValue = []
            newValue = [...value]
            newValue[index] = event.target.value
            setValue(newValue)
        }

    };

    const handleChange = (ActionID) => (event) => {
        let index = rows.findIndex(obj => obj.ActionID === ActionID)
        rows[index].TypeID = event.target.value
        let date = new Date()

        switch (event.target.value) {
            case 1:
                date.setDate(date.getDate() + 1);
                break;
            case 2:
                date.setDate(date.getDate() + 10);
                break;
            case 3:
                date.setDate(date.getDate() + 5);
                break;
            case 4:
                date.setDate(date.getDate() + 5);
                break;
            case 5:
                if (date.getDate() < 25) {
                    date.setDate(25);
                } else {
                    date.setMonth(date.getMonth() + 1);
                    date.setDate(25);
                }
                break;
            default:
            // code block
        }
        rows[index].ActionDateTime = date
    };

    const handleChangeDateAction = (ActionID) => (newDate) => {
        let index = rows.findIndex(obj => obj.ActionID === ActionID)
        rows[index].ActionDateTime = new Date(newDate)
        setFilterDate(current => !current)
    }

    const AddButton = async () => {
        confirmAlert({
            title: 'ยืนยันที่จะบันทึก Action?',
            message: 'คุณแน่ใจหรือที่จะทำสิ่งนี้',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        setDisable(true)
                        selected.map((ActionID, i) => {
                            let index = rows.findIndex(obj => obj.ActionID === ActionID)
                            let d = new Date(rows[index].ActionDateTime);
                            let ActionNewOnDate = moment(d).format('YYYY-MM-DD');

                            userHttpServices.collectorAction(token, rows[index].ActionID, rows[index].TypeID, ActionNewOnDate)
                                .then((res) => {
                                    // console.log(res)

                                    if (rows[index].AssetType) {
                                        let debitData = [];
                                        isDirectDebit.map((item) => {
                                            if (item.id_card === rows[index].Ku_ID) {
                                                debitData = item;
                                                return; // Exit the map loop if a match is found
                                            }
                                        });

                                        const object = {
                                            direct_debit: {
                                                contract_no: debitData.contract_no,
                                                payment_date: ActionNewOnDate,
                                                user_id: debitData.user_id,
                                                contract_id: debitData.contract_id,
                                                id_card: debitData.id_card,
                                                name: debitData.name,
                                                surname: debitData.surname
                                            }
                                        }

                                        directDebitHttpService.addManualPayment(object)
                                            .then((res) => {
                                                // console.log(res)
                                            })
                                    }

                                    if (i === selected.length - 1) {
                                        localStorage.setItem('success', true)
                                        window.location.reload()
                                    }
                                }).catch(err => {
                                    setDisable(false)
                                    alert('Save Failed Press Select Action')
                                })
                        })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => { }
                }
            ]
        })
    };

    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.text.primary, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} items selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        รายการทั้งหมด
                    </Typography>
                )}

                {numSelected > 0 ? (
                    // <Button style={{color:'#229954'}} color="inherit" variant="subtitle1" sx={{ flex: '1 1 10%' }} onClick={() => AddButton()}><AddIcon style={{color:'#229954'}}/> บันทึก</Button>
                    <Button variant="contained" color="success" style={{ marginRight: '1rem', width: '8rem' }} onClick={() => AddButton()} disabled={disable_but}><CachedIcon />  บันทึก</Button>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton onClick={() => tester()}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClickCheckbox = (event, ActionID) => {
        const selectedIndex = selected.indexOf(ActionID);
        let newSelected = [];

        // console.log('ActionID', ActionID)

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, ActionID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleClickSelect = (event, ActionID) => {
        let newSelected = [];

        if (event.target.getAttribute('data-value') === '0' || event.target.getAttribute('data-value') === null) {
            newSelected = newSelected.concat(selected);
            let index = selected.indexOf(ActionID)
            delete newSelected[index]
            // console.log('newSelected', newSelected)
            let data = newSelected.filter(Number);
            setSelected(data);

        } else {
            newSelected = newSelected.concat(selected, ActionID);
            let withoutDuplicates = [...new Set(newSelected)];
            setSelected(withoutDuplicates);
        }
    };

    const onClickIcon = (id) => {
        // console.log('PolicyID',id)
        window.open("/contact_detail/" + id)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tester = () => {
        setOpened(current => !current)
    }

    const handleFilterActionChange = (event) => {
        setFilterAction(event.target.value)
    }

    const addFilter = () => {
        if (rowsPerFilter < 5) {
            setRowsPerFilter(rowsPerFilter + 1)
        }
    }

    const resetFilterDate = () => {
        setDate(null)
    }

    const resetFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
    }

    const resetFilter = (valueIndex) => {
        switch (valueIndex) {
            case 1:
                setDate(null)
                break;
            case 2:
                setFilterAction('')
                break;
            case 3:
                settest3('')
                break;
            case 4:
                settest4('')
                break;
            case 5:
                settest5('')
                break;
            default:
            // code block
        }
    }

    const delFilterAction = (index) => {
        if (value[index] === 1) {
            resetFilterDate()
        } else {
            resetFilterAction()
        }
        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
        if (rowsPerFilter > 1) {
            setRowsPerFilter(rowsPerFilter - 1)
        }
    }

    const checkIfIdCardExists = (id) => {
        let result = false;

        isDirectDebit.map((item) => {
            if (item.id_card === id) {
                result = true;
                return; // Exit the map loop if a match is found
            }
        });

        return result
        // if (result === true) {
        //     return; // Stop the execution if the result is true
        // }

        // Continue with the remaining code...
    };
    const RrgisterDirectDebit = (row) => {
        confirmAlert({
            title: 'ยืนยันที่จะบันทึก ?',
            message: 'คุณแน่ใจหรือที่จะทำสิ่งนี้',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        userHttpServices.getContactDetail(token, row.PolicyID)
                        .then((res) => {
                            directDebitHttpService.syncDataToDirectDebit(res.data.data)
                            .then((res) => {
                                console.log(res)
                                if(res.data.success){
                                    alert("สำเร็จ")
                                }else{
                                    alert("ไม่สำเร็จ")
                                }
                            })
                        })
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => { }
                }
            ]
        })
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>

            {
                rows.length === 0 && !status ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <Grid> */}
                <Grid display={opened ? 'none' : ''}>
                    {
                        rowsPerFilter > 4 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(4)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 3 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(3)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 2 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(2)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 1 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(1)}
                            </Grid>
                            : null
                    }
                    <Grid container spacing={2} style={{ padding: '1rem' }}>
                        {filterGrid(0)}
                        <Grid item >
                            <Button variant="outlined" style={{ color: "#333", borderColor: "#333" }} onClick={() => addFilter()}>เพิ่มช่องการค้นหา</Button>
                        </Grid>
                        <Grid item >
                            <Button variant="contained" onClick={() => onClickFilterAction()}>ค้นหา</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <EnhancedTableToolbar numSelected={selected.length} />
                {/* <TableContainer> */}
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        // aria-labelledby="tableTitle"
                        // size='medium'
                        stickyHeader aria-label="sticky table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.ActionID);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClickCheckbox(event, row.ActionID)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{row.ActionOnDate}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.ActionCount}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.Policy_no}</TableCell>
                                            <TableCell>{row.Ku_name}</TableCell>
                                            <TableCell>{row.ProdName}</TableCell>
                                            <TableCell>{row.PolicyGroup}</TableCell>
                                            <TableCell>{row.EN_Followup}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.TodayBalance.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.InterestRemaining.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.PPP.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.PaymentRemaining.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.Budget}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.remark_pay}</TableCell>
                                            <TableCell>
                                                <FormControl sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}>
                                                    <Select
                                                        style={{ width: 130 }}
                                                        size="small"
                                                        labelId="select-label"
                                                        id="select"
                                                        value={row.TypeID}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onChange={handleChange(row.ActionID)}
                                                        onClick={(event) => handleClickSelect(event, row.ActionID)}
                                                    >
                                                        <MenuItem value={0} style={{ display: row.AssetType ? 'none' : 'block' }}><em>Action</em></MenuItem>
                                                        {
                                                            action.map((act, index) => {
                                                                return <MenuItem key={index} value={act.ID} style={{ display: (row.AssetType && act.ID !== 12) ? 'none' : 'block' }}>{act.Name}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                {/* {console.log(row.ActionDateTime)} */}
                                                {
                                                    row.TypeID !== 0 ?
                                                        // false ?
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <MobileDatePicker
                                                                inputFormat="YYYY-MM-DD"
                                                                value={row.ActionDateTime}
                                                                onChange={handleChangeDateAction(row.ActionID)}
                                                                renderInput={(params) => <TextField {...params} size="small" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />}
                                                            />
                                                        </LocalizationProvider>
                                                        : ""
                                                }
                                            </TableCell>
                                            <TableCell>{row.last_pay.split(' ')[0]}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}><Select
                                                size="small"
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{ width: 100 }}
                                                // disabled={checkIfIdCardExists(row.Ku_ID)}
                                            >
                                                <MenuItem><em>Action</em></MenuItem>
                                                <MenuItem value={2} onClick={() => RrgisterDirectDebit(row)}>สมัคร/เปลี่ยนธ. Direct Debit</MenuItem>
                                            </Select>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                {checkIfIdCardExists(row.Ku_ID) ? <VscCheck fontSize={18} /> : <VscChromeClose fontSize={18} />}
                                            </TableCell>
                                         
                                            <TableCell>
                                                <FormGroup>
                                                    {/* TypeID 12 is นัดชำระ */}
                                                    <FormControlLabel disabled={!(row.TypeID === 3 && checkIfIdCardExists(row.Ku_ID))} control={<Switch onChange={(event) => { rows[index].AssetType = event.target.checked; setActionSwitch(!actionSwitch) }} />} label="" />
                                                </FormGroup>
                                            </TableCell>
                                            <TableCell><IconButton onClick={() => onClickIcon(row.PolicyID)}><SearchIcon color="primary" /></IconButton></TableCell>
                                        </TableRow>

                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {/* {rows.length === 0 ? <TableFooter fullwidth ><TableCell fullwidth>Total Number of Rows is 3</TableCell></TableFooter> : ""} */}

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box >
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <MyApp />
        </SnackbarProvider>
    );
}